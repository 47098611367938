@import '../index.css';

#LandLogoContainer {
  /* height: 90%; */
  max-height: 170px;
  margin-left: 3%;
  margin-top: 2%;
}

.logo {
  height: 170px;
}
#webFormWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(127,94,126,1);
}

#formHeader {
    display: -webkit-flex;
    display: flex;
    height: 19%;
    width: 100%;
    -webkit-align-items: center;
    align-items: center;
}

#sectionTitle{
  font-family: 'Adamina-Regular';
  font-size: 34px;
  color: #000;
  margin: 20px 0;
}
#sectionTitle::before {
  content: "";
  display: block;
  width: 20px;
  height: 34px;
  background-color: #000;
  left: 10px;
  position: absolute;
}

.container {
  margin: 20px;
  background-color: #fff;
  padding: 20px;
  font-family: 'Adamina-Regular';
  font-size: 20px;
}

.inner {
  border-radius: 3px;
  width: 800px;
  margin: 20px auto;
  padding: 40px;
  border: 1px solid #f5f5f5;
  position: relative;
}

.form-group {
  margin-bottom: 15px;
  width: 320px;
}

.form-group label {
  color: #444;
  font-size: 20px;
  height: 20px;
  position: absolute;
}

.form-group label.checkBox {
  color: #444;
  font-size: 20px;
  height: 20px;
  position: static;
}

.cityState {
  display: flex;
  width: 300px;
}

.cityState .form-control {
  width: 70px;

}
.cityState #city {
  width: 200px;
  margin-right: 10px;
}

.form-control {
  height: auto;
  padding: 10px;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #ccc;
  color:#000;
  font-family: 'Adamina-Regular';
  font-size: 19px;
}

.form-control:focus {
  border: 2px solid #3384FF;
  box-shadow: none;
}

textarea {
  font-family: 'Adamina-Regular';
  font-size: 19px;
}

.form-control[type="radio"] {
  width: 40px;
  height: 20px;
  margin-bottom: 20px;
}

.userTypeInfo {
  margin-top: 30px;
  color:grey;
}

.existingUserInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  font-weight: bold;
}

.formButton {
  /* width: 300px; */
  padding: 15px 50px;
  /* height: 50px; */
  margin: 0 10px 0 0;
  background-color: #1B0E26;
  color: #fff;
  font-family: 'Adamina-Regular';
  font-size: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.addMorePostsInfo {
  width: 100%;
  padding: 20px;
  background-color: #fafafa;
  border-color: #ccc;
  border-radius: 3px;
  margin-right: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: center;
}
.addMore {
  background-color: #4F63FF;
  color: #fff;
}
.numberOfPostsInfo {
  width: 100%;
  background-color: #4F63FF;
  font-size: 18px;
  color: #fff;
  
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
  /* display: flex; */
}

.postHeaderWrapper {
  display: flex;
  padding: 10px 0;
  width: 100%;
}

.toggleViewAllPosts {
  background-color: #fff;
  border: none;
  margin: 10px;
  padding: 10px 30px;
  font-weight: bold;
  border: 1px solid transparent;
}

.toggleViewAllPosts:hover {
  background-color: #4F63FF;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
}

.listOfPostsWrapper {
  background-color: #fafafa;
  border: 1px solid #4F63FF;
  display: flex;
  justify-content: center;
  flex-direction: column;
  
}

.postDetailsWrapper {
  display: flex;
  /* justify-content: center; */
  color: grey;
  font-size: 13px;
  font-weight: 200;
  font-family: arial;
  text-align: left;
  /* align-items: center;
  align-self: center; */
  padding: 10px 100px;
  border: 1px dotted #ccc;
  margin: 10px 20px;
  border-radius: 5px;
  width: 500px;
}

.postDetailsWrapper span {
  font-weight: 400;
  color: #000;
}

.postDetailsWrapper span.type {
  color: green;
  font-weight: bold;
  text-transform: capitalize;
}



.postDetailsWrapper h1 {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #4F63FF;
  color: #fff;
  border-radius: 75px;
  margin: 0 10px;
  padding: 20px;
}



.errorMessage {
  color: red;
  font-size: 16px;
  margin-top: 5px;
}

.divider {
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  margin: 20px 0;
}

.checkboxRow {
  display: flex;
  width: 300px;
  justify-content: flex-start;
  align-items: center;
}

.checkboxRow label {
  margin-left: 10px;
}

.isResetting {
  width: 100%;
  height:300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  font-weight: bold;
}

.stripeCardElementWrapper {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #fafafa;
}

.showHideImageBtn {
  background-color: #4F63FF;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
}
.imageStyle {
  -webkit-box-shadow: -11px 28px 58px -8px rgba(97,90,97,0.75);
  -moz-box-shadow: -11px 28px 58px -8px rgba(97,90,97,0.75);
  box-shadow: -11px 28px 58px -8px rgba(97,90,97,0.75);
}

.userDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 90%;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.userDetails p:nth-child(1) {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  color: grey;
}

.userDetails span {
  font-size: 16px;
  font-weight: bold;
}

.nextInfo {
  margin-top: 20px;
  font-weight: 100;
  color: #353958;
}

.userTypeHeader {
  margin-bottom: 10px;
  color:grey;
}