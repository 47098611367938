@import '../../../index.css';

#MissionContainer {
    height: 864px;
    width: 38%;
    background-color: #FBF9FB;
}

#MissionTop {
    display: -webkit-flex;
    display: flex;
    height: 19%;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
}

#MissionHead {
    background-color: #855B80;
    height: 50%;
    width: 3.5%;
    margin-left: 7.5%;
}

#OurMissionText{
    font-family: 'Adamina-Regular';
    font-size: 34px;
    margin-left: 2%;
    color: #1B0E26;
}

.MissionContent{
    height: 27%;
    width: 100%;
    background-color: #FBF9FB;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.MissionSubHead {
    display: -webkit-flex;
    display: flex;
}

.MissionSubHeadText{
    font-family: 'Adamina-Regular';
    font-size: 30px;
    margin-left: 18%;
    border-bottom: 1px solid black;
    padding-bottom: .5%;

}

#MissionSubHeadList{
    height: 26px;
    margin-left: 2% ;
    margin-top: 1.3%;
}

#MissionSubHeadGive{
    height: 30px;
    margin-left: 2% ;
    margin-top: 1.2%;
}

#MissionSubHeadHeart{
    height: 28px;
    margin-left:2% ;
    margin-top: 1.5%;
}

.MissionBodyText {
    margin-left: 18.3%;
    margin-top: 1.2%;
    font-size: 18px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 1.5rem;
}

@media only screen and (max-width: 1024px) {

    #MissionContainer {
        height: 530px;
        width: 100%;
        padding-bottom: 20px;
    }

    .MissionContent {
        height: auto;
        margin-bottom: 40px;
    }

    .MissionSubHeadText {
        margin-left: 7.5%;
        margin-right: 7.5%;
    }

    .MissionBodyText {
        margin-left: 7.5%;
        margin-right: 7.5%;
    }
    
}

@media only screen and (max-width: 420px) {
    #MissionContainer {
        height: auto;
    }

    #MissionTop {
        height: 100px;
    }
}