#container {
  width: 100%;
  height:300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  font-weight: bold;
  flex-direction: column;
}

.actionButton {
  background-color: #444;
  color: #fff;
  border-radius: 75px;
  margin: 0 10px;
  padding: 10px;
  width: 100px;
  margin-top: 20px;
  border: 0;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}