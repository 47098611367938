@import '../../../index.css';

#FooterFrame{
    height: 324px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

#FooterBody{
    height: 100%;
    width: 100%;
    /* width: 1980px; */
    background-color: white;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

#FooterCopyright {
    text-align: center;
    font-family: 'poppins';
    font-weight: 400;
    font-size: 28px;
    color: #1B0E26;
}

#FooterTitleContainer{
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:center ;
    margin-top: 1.5%;
}

#FootEQIcon {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

#FootEQTitle{
    font-family: 'francisco_serial-regular';
    font-size: 64px;
    color: #1B0E26;
    letter-spacing: .11em;
    margin-left: 1.5%;
}

#TermsContainer{
    display: flex;
    justify-content: center;
}

.TermLinks{
    margin: 10px 10px;
}

@media only screen and (max-width: 768px) {
    #FooterFrame {
        height: auto;
    }

    #FooterBody {
        margin: 40px 0px;
        height: auto;
    }

    #FooterTitleContainer {
        height: auto;
    }

    #FooterCopyright {
        margin: 0px 20px;
        margin-bottom: 10px;
        font-size: 20px;
    }

    #FootEQIcon {
        width: auto;
        height: 80px;
        object-fit: contain;
        max-width: unset;
        max-height: unset;
    }

    #FootEQTitle {
        font-size: 30px;
    }
}

@media only screen and (max-width: 425px) {
    #FooterTitleContainer {
        flex-direction: column-reverse;
    }

    #FootEQTitle {
        margin: 0px;
        margin-bottom: 20px;
    }
}