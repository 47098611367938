@import 'rsuite/dist/rsuite.min.css';

.filterWrapper {
  /* position: fixed; */
  /* top: 80px; */
  /* right: 30px; */
  /* z-index: 1000; */
  width: 300px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  border-radius:  30px;
  margin-right: 20px;
}

/* .rs-slider-progress-bar {
  background-color: #3C2A42;
}
.rs-slider-handle:before {
  border-color: #3C2A42;
} */
/* .filterWrapperVisible {
  position: fixed;
  top: 100px;
  right: 5px;
  z-index: 1000;
  width: 250px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
} */

#filterToggle {
  border-radius: 5px;
  background-color: #fff;
  border: 0;
  padding: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-self: flex-end;
}

#filterToggle:hover {
  cursor: pointer;
}

#filters {
  background-color: #f5f5f5;
  /* border: .5px solid #e5e5e5; */
  margin-top: 20px;
  padding: 20px;
  border-radius:  30px;
  width: 100%;
  margin-left: 20px;
  /* overflow-y: scroll; */
  /* height: 600px; */
}

.filterSection {
  margin-bottom: 10px;
  background-color: #fff;
  -webkit-display: flex;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px 20px;
}

.filterSectionLabel {
  font-weight: 700;
  margin-bottom: 10px;
}

.sliderMarkers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 10px;
  margin: 5px -5px 10px -5px;
}

.sliderMarkers>span>span {
  font-weight: 700;
}

.maxHeight {
  width: 85px;
}

#filterButtonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

#applyFilters {
  background-color: #3C2A42;
  border: .5px solid #e5e5e5;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  -webkit-flex: 3;
  flex: 3;
  color: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: 900;
  border-radius: 0 10px 10px 0;
}

.resetFilters {
  border: .5px solid #e5e5e5;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  flex: 1;
  transition: all 0.3s ease-in-out;
  font-weight: 900;
  border-radius: 10px 0 0 10px;
}

.resetResultFilters {
  border-radius: 10px;
}

#applyFilters:hover,
.resetFilters:hover {
  background-color: #00B4DB;
}

.resetFilters:hover {
  color: #fff;
}

.noResults {
  -webkit-display: flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 20px 10px;
  background-color: #fafafa;
  padding: 16px;
  letter-spacing: 2px;
  max-width: 600px;
  margin: 10px auto;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.noResultsHeader {
  font-weight: 700;
  font-size: 18px;
  color: #444;
}

.noResultsText {
  font-size: 18px;
  color: #444;
  margin-top: 10px;
  text-align: center;
}

.filterResults {
  border-color: #ccc;
  border-width: 1px;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fafafa;
}

.filterText {
  letter-spacing: 2px;
  flex-wrap: wrap;
  font-size: 13px;
  text-align: center;
}

.filterLabel {
  font-weight: 700;
  font-size: 16px;
}

@media screen and (max-width: 1420px) {

  .filterWrapper,
  .filterWrapperVisible {
    width: 1000px;
    top: 85px;
    align-self: center;
    padding: 10px 30px;
  }

  #filters {
    width: auto;
  }

  .noResults {
    max-width: 600px;
  }
}

@media screen and (max-width: 1020px) {

  .filterWrapper {
    width: 720px;
  }
  #filters {
    width: auto;
  }
}

@media screen and (max-width: 740px) {

  .filterWrapper,
  .filterWrapperVisible {
    width: 500px;
    top: 85px;
  }
  #filters {
    width: auto;
  }

  .noResults {
    max-width: 600px;
  }
}

@media screen and (max-width: 550px) {

  .filterWrapper {
    width: 400px;
    margin: 20px 0px;
  }
  #filters {
    width: auto;
  }
}

@media screen and (max-width: 420px) {

  .filterWrapper {
    width: calc(100% -40px);
    padding: 10px 5px;
  }
  #filters {
    width: auto;
  }

  .noResults {
    max-width: 360px;
  }
}



@media screen and (max-width: 360px) {

  .filterWrapper {
    width: calc(100% -20px);
  }
  .noResults {
    max-width: 340px;
  }
}