.pageTitleContainer {
  -webkit-display: flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  padding: 30px 10px;
  height: 100px;
  width: 1400px;
}

.pageTitleBar {
  height: 100px;
  width: 30px;
  background-color: #3C2A42;
  margin-right: 10px;
}
.pageTitleBar.scaleDown,
.pageTitleContainer.scaleDown   {
  height: 70px;
}

.pageTitle {
  font-size: 34px;
  font-weight: bold;
  color: #3C2A42;
  font-family: 'Adamina-Regular';
}

.pageTitle.scaleDown {
  font-size: 24px !important;
}

@media screen and (max-width: 550px){

  .pageTitleContainer {
    width: calc(100% - 20px)!important;
    padding: 10px;
  }

  .pageTitleBar {
    height: 70px;
    width: 20px;
    margin-right: 10px;
  }
}