@import '../../../index.css';

#SubscribeFrame{
    width: 100%;
    height: 378px;
    background-color: #FBF9FB;
    display: flex;
    justify-content: center;
    align-items: center;
}

#SubscribeRibbonBody {
    width: 100%;
    height: 100%;
    max-width: 1980px;
    background-color:#FBF9FB ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#SubscribeRibbonHead{
   font-family: 'Adamina-Regular';
   font-size: 56px;
   color: #1B0E26;
   margin-bottom: 1%;
}

#SubsribeRibbonSubhead{
    font-family: 'poppins';
    font-size: 28px;
    font-weight: 400;
    line-height: 1.5rem;
    color: #3C2A42;
}

#SubsribeRibbonEmailContainer{
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    margin-top: 1.5%;
}

#SubsribeEmailTxt{
    /* width: 70%; */
    width: 500px;
    font-size: 24px;
    font-family: 'poppins';
    color: #1B0E26;
    font-weight: 400;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #1B0E26;
}

#SubscribeEmailBtn{
    border: 1px solid #1B0E26;
    height: 70%;
    width: 15%;
    max-height: 40px;
    margin-top: 2%;
    margin-left: 4%;
    color: #855B80;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    font-family: 'poppins';
    font-size: 22px;
    
}    

::placeholder {
    color: #855B80;
    opacity: 1;
    }

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #855B80;
}
    
::-ms-input-placeholder { /* Microsoft Edge */
    color: #855B80;
}

#SubscribeEmailBtn:hover{
    background-color: #3C2A42;
    color: #FBF9FB;
}

textarea:focus, input:focus{
    outline: none;
}


.SubscribeEmailError {
    color: red;
    margin: 0px 30px;
    margin-top: 10px;
    height: 21px;
    font-size: 18px;
    text-align: center;
}

.SubscribeEmailComplete {
    margin: 0px 30px;
    margin-top: 10px;
    height: 21px;
    font-size: 18px;
    text-align: center;
}

@media only screen and (max-width: 768px) {

    #SubscribeFrame {
        height: auto;
        padding: 40px 0px;
    }

    #SubscribeRibbonHead {
        font-size: 40px;
        margin: 0px 20px;
        margin-bottom: 10px;
        text-align: center;
    }

    #SubsribeRibbonSubhead {
        font-size: 20px;
        margin: 0px 20px;
        text-align: center;
    }

    #SubsribeEmailTxt {
        width: 200px;
        font-size: 18px;
    }

    #SubscribeEmailBtn {
        width: 100px;
        font-size: 18px;
    }

}

@media only screen and (max-width: 425px) {

    #SubscribeRibbonHead {
        font-size: 30px;
    }

    #SubsribeRibbonSubhead {
        font-size: 18px;
    }

    #SubsribeRibbonEmailContainer {
        flex-direction: column;
        width: 80%;
        align-items: flex-end;
        margin-top: 20px;
    }

    #SubsribeEmailTxt {
        width: 100%;
        font-size: 18px;
    }

    #SubscribeEmailBtn {
        /* width: 100%; */
        margin: 0px;
        height: auto;
        line-height: unset;
        padding: 5px 0px;
        margin-top: 10px;
    }

}
