@import '../../../index.css';

#NewsNavFrame{
    width: 100%;
    display: flex;
    justify-content: center;
}

#NewsNavContainer{
    margin-top:15px;
    display: flex;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    max-width: 1920px;

}

#NewsNavBrandContainer {
    display: flex;
    justify-content: flex-start;
    margin-left: 40px;
    align-items: center;
    text-decoration: none;
}

#NavLogo{
    height: 70px;
    object-fit: contain;
}

#NavTitle{
    font-family: 'francisco_serial-regular';
    font-size: 36px;
    color: #1B0E26;
    letter-spacing: .06em;
    margin-left: 20px;
}

#LinkContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#HomeLinkContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
}

#HomeLink{
    display: flex;
    align-items: center;
    margin-right: 30px;

}

#NewsLink{
    display: flex;
    align-items: center;
    margin-right: 45px;
}

.NavText {
    font-family: poppins, sanserif;
    font-weight: 600;
    color: #855B80;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    .NavText {
        font-size: 16px;
    }
    #HomeLinkContainer {
        font-size: 18px !important;
    }
    #HomeLinkContainer a.NavText {
        font-size: 16px;
        margin: 10px;
    }
}

@media screen and (max-width: 550px) {

    #NewsNavBrandContainer {
        margin-left: 30px;
    }
    
    #NavLogo{
        height: 50px;
    }

    #NavTitle{
        font-size: 30px;
        letter-spacing: .04em;
        margin-left: 15px;
    }

    #HomeLink{
        margin-right: 20px;
    }

    #NewsLink{
        margin-right: 30px;
    }

    .NavText {
        font-size: 16px;
    }
    #HomeLinkContainer {
        font-size: 18px !important;
    }
    #HomeLinkContainer a.NavText {
        font-size: 16px;
    }

}

@media screen and (max-width: 420px) {
    #MobileHamburger{
        display: flex;
        height: 50px;
        width: auto;
        margin-right: 30px;
        cursor: pointer;
    }   
    
    .OpenNav {
        display: flex;
        position: fixed;
        top: 100px;
        background-color: #855B80;
        height: 200px;
        width: 100vw;
    }
    
    #MobileLinkContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
    }

    .NavBorder{
        display: flex;
        height: 1px;
        width: 100vw;
        background-color: black;
    }

    .MobileLink{
        height: 99.5px;
        line-height: 99.5px;
        display: flex;
        justify-content: flex-end;
        font-family: poppins, sanserif;
        font-weight: 400;
        color: #F6F6F6;
        cursor: pointer;
        text-decoration: none;
        font-size: 30px;
        margin-right: 40px;
    }

}