#RibbonFrame{
    width: 100%;
    height: 129.6px;
    display: flex; 
    justify-content: center;
    background-color: #F6F6F6;
}

#RibbonContainer {
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
    display: flex;
    justify-content: space-between;
    max-width: 1980px;
}

#RibbonText {
    font-family: 'Adamina-Regular';
    font-size: 36px;
    margin-left: 2.5%;
    align-self: center;
    color: #855B80;
}

#BtnContainer{
    height: 100%;
    width: 760px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
}

#BannerAndroidLogo {
    height: 66%;
}

#AndroidBtn {
    height: 60%;
    width: 50%;
    max-width: 380px;
    min-height: 50px;
    background-color: #855B80;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;
    font-size: 20px;
}

#BannerAppleLogo{
    height: 70%;
    padding-right: 2px;
}

#AppleBtn {
    height: 60%;
    width: 50%;
    max-width: 380px;
    min-height: 50px;
    background-color: #3C2A42;
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;
    font-size: 20px;
}

@media screen and (max-width: 1388px) {

    #RibbonText {
        font-size: 28px;
    }

    #BtnContainer {
        width: auto;
    }

    #BannerAndroidLogo {
        height: 40px;
    }

    #AndroidBtn { 
        width: auto;
        height: auto;
        min-height: unset;
        padding: 10px 20px;
        font-size: 18px;
    }

    #BannerAppleLogo{
        height: 40px;
        padding-right: 2px;
    }

    #AppleBtn {
        width: auto;
        height: auto;
        min-height: unset;
        padding: 10px 20px;
        font-size: 18px;
    }
}

@media screen and (max-width: 1024px) {

    #RibbonFrame {
        height: auto;
    }

    #RibbonContainer {
        flex-direction: column;
        height: 100%;
    }
    #RibbonText {
        margin: 20px 20px;
        text-align: center;
    }

    #BtnContainer {
        justify-content: center;
        padding-right: 0px;
        margin-bottom: 20px;
    }
}


@media screen and (max-width: 670px) {

    #BtnContainer {
        flex-direction: column;
        height: auto;
        align-items: center;
    }

    #BannerAndroidLogo {
        height: 30px;
    }

    #AndroidBtn {
        padding: 10px 20px;
        font-size: 16px;
        margin-bottom: 20px;
    }

    #BannerAppleLogo{
        height: 30px;
    }

    #AppleBtn {
        padding: 10px 20px;
        font-size: 16px;
        margin: 0px;
    }
}

@media screen and (max-width:316px) {

    #AndroidBtn {
        padding: 10px 10px;
        font-size: 14px;
    }

    #AppleBtn {
        padding: 10px 10px;
        font-size: 14px;
    }
}