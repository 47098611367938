@import '../../../index.css';

body {
    background-color: #EAE8EB;
}


#CardFrame{
    display: flex;
    width: 100%;
    /* justify-content: center; */
}

#CardHolder{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% - 320px);
    margin: 0 auto;
}

.CardContainer {
    background-color: #F6F6F6;
    width: calc( 50% - 80px);
    height: 550px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 5px 5px 12px 0px rgba(54,53,53,0.15);
    color:black;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.CardContainerFull {
    background-color: #F6F6F6;
    width: calc( 100% - 80px);
    height: 550px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 5px 5px 12px 0px rgba(54,53,53,0.15);
    color:black;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.CardContainer:hover {
    box-shadow: 5px 5px 12px 0px rgba(34, 44, 189, 0.15);
    background-color: #fff;
}

#NewsCardTop{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

#NewsCardTopLeft{
    margin-left: 40px;
    display: flex;
    align-items: center;
}

#ProfImgContainer {
    height: 80px;
    border-radius: 50%;
    display: flex;
}

#ProfileImg{
    object-fit: contain;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

#ProfileName{
    font-family: poppins, sans-serif;
    font-weight: 400;
    color: #1B0E26;
    font-size: 26px;
    margin-left: 20px;
    text-transform: capitalize;
}

#StatusBar{
    display: flex;
    justify-content: center;
    height: 45px;
    width: 120px;
    border-radius: 40px;
    margin-right: 20px;
    line-height: 45px;
    font-family: poppins, sans-serif;
    font-weight: 600;
    color: #F6F6F6;    
    box-shadow: 5px 3px 8px 0px rgba(54,53,53,0.15);
    align-self: center;
    font-size: 18px;
    text-transform: capitalize;
}

#DetailCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#DetailRow{
    display: flex;
    margin-top: 2px;
    margin-left: 145px;
}

#DetailRow2 {
    display: unset;
    margin-top: 2px;
}

.Details{
    font-family: poppins, sans-serif;
    font-weight: 400;
    color: #7F5E7E;
    font-size: 14px;
    margin-right: 15px;
    text-align: center;
    background-color: #fafafa;
    border: .3px solid #eee8e8;
    padding: 1.5px;
    border-radius: 5px;
}

#NewsTitle{
    display: flex;
    margin-left: 40px;
    margin-top: 20px;
    font-family: poppins, sans-serif;
    font-weight: 600;
    font-size: 24px;
}

#NewsDescript{
    display: flex;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 5px;
    font-family: poppins, sans-serif;
    font-weight: 400;
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

#NewsBottom{
    flex-grow: 1;
    height: 180px;
    display: flex;
    align-items: center;
    border-radius: 25px;
    margin-top: 20px;
    margin-bottom: 40px;
}

#BottomLeftLrg{
    display: flex;
    height: 180px;
    width: auto;
    /* max-width: 600px; */
    margin-left: 40px;
    border-radius: 25px;
    overflow: hidden;
}

#BottomLeftSm{
    display: flex;
    height: 180px;
    width: auto;
    max-width: 400px;
    margin-left: 40px;
    border-radius: 25px;
    overflow: hidden;
}

#BottomMiddleFull {
    display: flex;
    height: 180px;
    width: auto;
    max-width: 400px;
    margin-left: 30px;
    border-radius: 25px;
    overflow: hidden; 
}

#BottomMiddleHalves {
    display: flex;
    flex-direction: column;
    height: 180px;
    width: 270px;
    margin-left: 30px;
    max-width: 400px;
    overflow: hidden;
    justify-content: space-between;
}

.MiddleHalf {
    height: 80px;
    width: 180px;
    overflow: hidden;
    border-radius: 25px;
}

.MiddleHalf > img {
    min-height: 100%;
    max-width: 100%;
    object-position: top;
}

#BottomRight{
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;
    width: auto;
    margin-left: 30px;
}

#ExtraPicCol{
    display: flex;
    height: 110px;
    width: 125px;
    background-color: #EAE8EB;
    border-radius: 25px;
    margin-right: 20px;
}

#ExtraPicTxt{
    width: 100%;
    font-size: 22px;
    line-height: 110px;
    text-align: center;
    font-family: poppins, sans-serif;
    font-weight: 600;
    color: #B3A1B3;
}

#BottomRightNoExtra{
    display: flex;
    justify-content: flex-end;
    height: 100%;
    flex-grow: 1;
    width: auto;
    margin-left: 30px;
}

#LikeShareCont{
    margin-top: 150px;
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-right: 50px;
}

.Row {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
}

.NewsImg{
    display: flex;
    object-fit: contain;
}

.LikeShareTxt{
    font-family: poppins, sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    margin-left: 10px;
}

.Icons{
    height: 40px;
    width: auto;
}

a {
    text-decoration: none !important;
}

#CardHolder a {
    color: #1B0E26 !important;
}

@media screen and (max-width: 1820px) {
    .Details{
        font-size: 12px;
        padding: 1.5px;
    }
}
@media screen and (max-width: 1650px) {

    .CardContainer {
        height: auto;
    }
    #DetailRow,
    #DetailRow2{
        margin-top: 15px;
        margin-left: 40px;
        margin-right: 40px;
        flex-direction: column;
        align-items: flex-start;
    }

    
}
@media screen and (max-width: 1420px) {

    #CardHolder{
        width: calc(100%);
    }

    .CardContainer {
        width: 1000px;
        height: auto;
    }

    #CardFrame{
        flex-direction: column;
    }
    
    .MiddleHalf {
        width: 100%;
    }

    .MiddleHalf > img {
        min-height: 100%;
        height: 100%;
        min-width: 100%;
        max-width: unset;
    }

    #BottomMiddleHalves {
        width: 110px;
        margin-left: 20px;
    }
   
    #ExtraPicCol{
        width: 110px;
    }

    #LikeShareCont{
        width: 305px;
        margin-right: 30px;
    }
    
}

@media screen and (max-width: 1020px) {
    
    .CardContainer {
        width: 720px;
    }

    #NewsBottom{
        flex-direction: column;
        height: auto;
        justify-content: center;
    }

    #BottomLeftLrg{
        margin-left: 0;
        height: 280px;
    }


    #BottomLeftSm{
        max-width: unset;
        margin-left: 0px;
        justify-content: center;
        height: 280px;
    }

    #BottomMiddleHalves {
        flex-direction: row;
        height: auto;
        margin-left: 0px;
        margin-top: 10px;
        width: 450px;
        max-width: unset;
    }

    #BottomMiddleFull{
        height: auto;
        max-height: 250px;
        max-width: unset;
        margin-left: 0px;
        margin-top: 10px;
    }

    #BottomMiddleFull > Img{
        width: 450px;
    }


    .MiddleHalf {
        height: auto;
        width: 220px;
        border-radius: 25px;
    }

    .MiddleHalf > img {
        /* min-width: 100%; */
        /* max-width: unset; */
        height: auto;
        width: 100%;
    }

    #BottomRight{
        flex-direction: column;
        align-items: center;
        margin-left: 0px;
        margin-top: 10px;
    }

    #ExtraPicCol{
        width: 450px;
        height: 80px;
    }

    #ExtraPicTxt{
        line-height: 80px;
    }

    #LikeShareCont{
        margin:0;
        margin-top: 10px;
        flex-direction: row;
        width: auto;
    }

    .Row{
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media screen and (max-width: 740px){

    .CardContainer {
        width: 500px;
    }

    #ProfImgContainer {
        height: 70px;
        display: flex;
    }

    #ProfileName{
        font-size: 18px;
        margin-left: 15px;
    }

    #StatusBar{
        height: 35px;
        border-radius: 40px;
        line-height: 35px;
        font-size: 18px;
        margin-right: 5px;
    }

    #DetailRow,
    #DetailRow2{
        margin-top: 15px;
        margin-left: 40px;
        margin-right: 40px;
        justify-content: space-between;
        flex-direction: column;
    }
    
    .Details{
        margin-right: 0px;
        font-size: 12px;
        margin-right: 15px;
        text-align: left;
        background-color: transparent;
        border: 0;
        padding: 1.5px;
        border-radius: 5px;
    }

    #NewsTitle{
        margin-top: 10px;
        font-size: 22px;
    }

    #NewsDescript{
        margin-top: 5px;
        font-size: 16px;
    }

    #NewsBottom{
        margin-bottom: 20px;
    }

    #LikeShareCont{
        width: 400px;
    }

    #BottomRightNoExtra {
        margin-left: 0px;
    }

    .LikeShareTxt{
        font-size: 18px;
        line-height: 25px;
        margin-left: 10px;
    }

    .Icons{
        height: 25px;
        width: auto;
    }
    #BottomLeftSm{
        transform: scale(.8);
    }
    .NewsImg{
        border-radius: 25px;
        transform: scale(.8);
    }

}

@media screen and (max-width: 550px){
    
    .CardContainer {
        width: 400px;
        margin: 20px 0px;
    }
    
    #DetailRow{
        justify-content: space-evenly;
    }

    #DetailRow2 {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px 40px; 
    }

    #BottomLeftLrg{
        height: 200px;
    }

    #BottomLeftSm{
        height: 200px;
    }

    #BottomMiddleFull{
        max-height: 200px;
    }

    #BottomMiddleFull > Img{
        width: 300px;
    }

    #BottomMiddleHalves {
        width: 300px;
    }

    .MiddleHalf {
        height: auto;
        width: 220px;
        margin: 0px 5px;
    }

    #ExtraPicCol{
        width: 300px;
        height: 80px;
    }
    
}

@media screen and (max-width: 420px){
    
    .CardContainer {
        /* width: 300px; */
        width: calc(100% - 40px);
    }

    #NewsCardTop{
        flex-direction: column;
    }

    #NewsCardTopLeft{
        margin: 0;
        justify-content: center;
    }


    
    #ProfileName{
        font-size: 20px;
    }

    #StatusBar{
        margin-left: 140px;
        margin-top: -10px;
        margin-right: 5px;
    }
    
    #DetailRow{
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }

    /* #DetailRow2 {
        margin: 0px 10px;
    } */

    #NewsTitle{
        margin-left: 32px;
        font-size: 16px;
    }

    #NewsDescript{
        margin-left: 32px;
        margin-right: 32px;
        font-size: 16px;
    }

    #BottomLeftLrg{
        height: 150px;
    }

    #BottomLeftSm{
        height: 150px;
    }

    #BottomMiddleFull{
        height: 150px;
    }

    #BottomMiddleFull > Img {
        width: 225px;
    }

    #BottomMiddleHalves{
        width: 240px;
    }

    #ExtraPicCol{
        width: 225px;
        height: 60px;
    }

    #ExtraPicTxt{
        line-height: 60px;
        font-size: 20px;
    }

    #LikeShareCont {
        flex-direction: column;
        width: 300px;
    }

    .Row{
        justify-content: center;
        margin: 15px 0px;
    }

    .LikeShareTxt{
        font-size: 16px;
    }


}