@import '../../../index.css';

#OurAppFrame{
    width: 100%;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
}

#OurAppContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    max-width: 1980px;
    margin-bottom: 40px;
}

#OurAppTop {
    display: flex;
    height: 150px;
    width: 100%;
    align-items: center;
}

#OurAppHead {
    background-color: #3C2A42;
    height: 39%;
    width: 1.33%;
    margin-left: 3%;
}

#OurAppText{
    font-family: 'Adamina-Regular';
    font-size: 46px;
    margin-left: .75%;
    color: #1B0E26;
}

#OurAppContentContainer {
    width: 80%;
    max-width: 800px;
}

#OurAppSubheads{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

.SubheadBoxes{
    /* height: 100%; */
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    
}

.SubheadBoxes:hover{
    background-color: #B1A5B0;
}

.SubheadBoxActive{
    /* height: 100%; */
    width: 25%;
    background-color: #3C2A42;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;

}

#StayOrganizedImg {
    width: 45%;
    margin-top: 5%;
}

.BaseSubheadTxt{
    font-family: 'Adamina-Regular';
    font-size: 28px;
    color: #1B0E26;
    margin-top: 2%;
    margin-right: 6%;
    margin-left: 6%;
    border-bottom: 1px solid transparent;
    padding-bottom: 2%;
}

.ActiveTxt {
    border-bottom: 1px solid #FBF9FB;
    color: #FBF9FB !important;
}

#FindTheHorseOfYourDreamsImg {
    width: 42%;
    margin-top: 5%;
}

#CompetitivePricingImg {
    width: 45%;
    margin-top: 5%;
}

#ExcitingNewFeaturesImg {
    width: 45%;
    margin-top: 5%;
}

#OurAppBody{
    width: 100%;
    /* height: 63%; */
    display: flex;
    position: relative;
    /* justify-content: center; */
    /* align-items: center; */
}

#BodyTxtContainer{
    /* height: 100%; */
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.OurAppBodyTxt{
    margin-left: 9%;
    font-size: 26px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 2.5rem;
    color: #FBF9FB;
}

.OurAppImg{
    margin: 30px 0px;
    height: auto;
    width: 85%;
}

#StayOrganizedBody{
    height: auto;
    width: 70%;
    background-color: #3C2A42;
    display: flex;
}

#FindtheHorseofYourDreamsBody{
    position: relative;
    height: auto;
    width: 70%;
    background-color: #3C2A42;
    /* margin-right: -7%; */
    left: 25%;
    display: flex;
}

#CompetitivePricingBody{
    position: relative;
    height: auto;
    width: 70%;
    background-color: #3C2A42;
    left: 5%;
    display: flex;
}

#ExcitingNewFeaturesBody{
    position: relative;
    height: auto;
    width: 70%;
    background-color: #3C2A42;
    left: 30%;
    display: flex;
}



#BodyImgContainer{
    /* height: 100%; */
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.OurAppMobleSubHeadDiv {
    width: 100%;
    padding-top: 20px;
    text-align: center;
    background-color: #3C2A42;
    color: white;
    font-weight: 700;
    font-size: 30px;
    display: none;
}

.OurAppMobleSubHeadDiv > p {
    margin: 0px 20px;
}

@media only screen and (max-width: 1024px) {
    .OurAppBodyTxt {
        font-size: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .OurAppMobleSubHeadDiv {
        display: block;
    }

    #OurAppSubheads > div > p {
        display: none;
    }

    #OurAppSubheads > div {
       padding-top: 10px;
    }

    #StayOrganizedBody{
        width: 100%;
    }
    
    #FindtheHorseofYourDreamsBody{
        position: unset;   
        width: 100%;
    }
    
    #CompetitivePricingBody{
        position: unset;
        width: 100%;
    }
    
    #ExcitingNewFeaturesBody{
        position: unset;
        width: 100%;
    }
}

@media only screen and (max-width: 547px) {

    #StayOrganizedBody{
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }

    #FindtheHorseofYourDreamsBody{
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }
    
    #CompetitivePricingBody{
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }
    
    #ExcitingNewFeaturesBody{
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }

    #BodyTxtContainer {
        width: 80%;
        margin-bottom: 20px;
    }

    .OurAppBodyTxt {
        margin: 0px;
    }

    #BodyImgContainer{
        width: auto;
    }

    .OurAppImg {
        width: 65%;
    }
}