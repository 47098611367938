#qrCodeContainer {
  position: relative;
  /* width: 200px;
  height: 45px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  /* padding: 7px 15px; */
  padding: 15px 50px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

#qrCodeContainer span {
  display: inline-block;
  margin-left: 20px;
  font-size: 25px;
  font-weight: 500;
  color:#855B80;
}

@media screen and (max-width: 768px) {
  #qrCodeContainer {
    position: relative;
    /* width: 150px;
    height: 45px; */
    padding: 15px 50px;
  }
  
  #qrCodeContainer span {
    display: inline-block;
    margin-left: 10px;
    font-size: 13px;
    font-weight: 600;
  }
}