@import '../../../index.css';

#LandingContainer{
    width: 100%;
    height: 950.4px;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
}

#BorderContainer {
    width: 100%;
    height: 100%;
    max-width: 1980px;
    background: rgb(127,94,126);
    background: linear-gradient(145deg, rgba(27,14,38,1) 0%, rgba(127,94,126,1) 100%);
    display: flex;
}

#LandContentContainer {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    background-color: white;
    margin: auto;    
    max-width: 1980px;
}

#LandContentContainer > div {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    
}

#LeftContentContainer {
    width: 55%;
    height: 100%;
    width: 100%;
}

#TopLeftLandContainer{
    display: flex;
    align-items: center;
    width: 100%;
    height: 22%;
    z-index: 1000;
}

#LandLogoContainer {
    height: 90%;
    max-height: 170px;
    margin-left: 3%;
    margin-top: 2%;
}

#LandEQIcon {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
 }

.BrandName{
    font-family: 'francisco_serial-regular';
    font-size: 70px;
    color: #1B0E26;
    letter-spacing: .11em;
    margin-left: 2%;
}

#MidLeftLandContainer {
    margin-top: 4%;
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
}

#LandingLine{
    height: 60%;
    width: 1px;
    background-color: #1B0E26;
    margin-left: 3%;
}

#LandingDescription{
    font-family: poppins, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    line-height: 230%;
    color: #855B80;
    margin-left: 3%;
    padding-left: 2%;
    border-left: solid 1px #1B0E26;
}


#RightContentContainer{
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4%;
    position: absolute;
    right: 0;
    z-index: 0;
}

#LandingScreen {
    height: auto;
    /* max-height: 700px; */
    max-width: 500px;
    width: 80%;
    z-index: 1;
}

#LowerContentContainer{
    position: absolute;
    height: 15%;
    width: 100%;
    bottom: 0px;
    background-color: #F6F6F6;
    z-index: 0;
    display: flex;
}

#SubscribeBtn {
    height: 60%;
    width: 20%;
    min-height: 50px;
    max-width: 380px;
    background-color: #1B0E26;
    margin-left: 2%;
    margin-top: 1.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;
    font-size: 24px;
    text-decoration: none;
}

@media screen and (max-width: 1750px) {
    #LandingDescription {
      font-size: 30px;
    }

    #MidLeftLandContainer {
        margin-top: 6%;
    }

    #LandingLine{
        height: 50%;
    }

    #SubscribeBtn {
        font-size: 20px;
        height: 55%;
        width: 17%;
        min-width: 250px;
    }
}


@media screen and (max-width: 1320px) {
    #LandingDescription {
      font-size: 26px;
    }

    #MidLeftLandContainer {
        margin-top: 6%;
    }

    #LandingLine{
        height: 45%;
    }

    #SubscribeBtn {
        font-size: 18px;
        height: 50%;
        width: 16%;
        min-width: 250px;
        margin-top: 2%;
    }

}

@media screen and (max-width: 1210px) {

    #LandLogoContainer{
        margin: 20px;
        height: auto;
        width: 70px;
    }

    .BrandName {
        font-size: 50px;
        margin-left: 0px;
    }
    
    #SubscribeBtn {
        font-size: 18px;
        height: 50%;
        width: 16%;
        min-width: 250px;
        margin-top: 2%;
    }

    #HomeLinkContainer a.NavText {
        font-size: 16px;
        margin: 10px;
    }
}

@media screen and (max-width: 930px) {
    #LeftContentContainer {
        width: 50%;
    }

    #RightContentContainer {
        margin-right: 0px;
        width: 50%;
        position:static;
    }

    #LandLogoContainer{
        width: 50px;
    }

    .BrandName {
        font-size: 40px;
        margin-left: 10px;
    }

    #HomeLinkContainer a.NavText {
        font-size: 16px;
        margin: 10px;
    }
}

@media screen and (max-width: 768px) {

    #LandingContainer {
        height: auto;
    }

    #LandContentContainer {
        width: 100%;
        height: 100%;
        margin: 20px;
    }

    #LandContentContainer > div {
        flex-direction: column;
        align-items: center;
    }

    #LeftContentContainer {
        width: 100%;
    }

    #RightContentContainer {
        width: 100%;
        position:static;
    }

    #LandingDescription {
        font-size: 23px;
        margin-right: 20px;
    }

    #LandingScreen {
        max-width: 300px;
    }

    #LowerContentContainer {
        position: unset;
        height: 85px;
        display: flex;
        align-items: center;
    }

    #SubscribeBtn {
        margin: 0px;
        margin-left: 20px;
        width: auto;
    }

    #HomeLinkContainer a.NavText {
        font-size: 16px;
        margin: 10px;
    }

}


@media screen and (max-width: 680px) {

    #TopLeftLandContainer {
        flex-direction: column;
        z-index: 1000;
    }

    #HomeLinkContainer {
        font-size: 18px !important;
    }

    #LandLogoContainer {
        width: 80px;
    }
    
    .BrandName {
        margin: 0px;
    }

    #MidLeftLandContainer {
        margin:20px 0px;
    }

    #LandingDescription {
        font-size: 20px;
        border: none;
        text-align: center;
    }
    
    #RightContentContainer { 
        margin-bottom: 20px;
        position:static;
    }

    #LowerContentContainer {
        justify-content: center;
    }

    #SubscribeBtn {
        font-size: 16px;
        width: auto;
        height: unset;
        min-width: unset;
        min-height: unset;
        padding: 10px 20px;
        margin: 0px;
    }
}
@media screen and (max-width: 425px) {

    #TopLeftLandContainer {
        flex-direction: column;
        z-index: 1000;
    }

    #LandLogoContainer {
        width: 80px;
    }
    
    .BrandName {
        margin: 0px;
    }

    #MidLeftLandContainer {
        margin:20px 0px;
    }

    #LandingDescription {
        font-size: 20px;
        border: none;
        text-align: center;
    }
    
    #RightContentContainer { 
        margin-bottom: 20px;
        position:static;
    }

    #LowerContentContainer {
        justify-content: center;
    }

    #SubscribeBtn {
        font-size: 16px;
        width: auto;
        height: unset;
        min-width: unset;
        min-height: unset;
        padding: 10px 20px;
        margin: 0px;
    }
}

@media screen and (max-width: 320px) {
    .BrandName {
        font-size: 30px;
    }

    #LandingDescription {
        font-size: 18px;
    }
}