/* You need to reset the box-sizing back to normal because the rsuite messes it for you...not cool */
*, :after, :before {
  -webkit-box-sizing: unset !important; 
  box-sizing: unset !important;
}

/* Since the rsuite package sliders handles depend on the 
box-sizing being set to border-box, need to update it 
*/
#CardFramePostCard{
  display: flex;
  justify-content: center;
}
.rs-slider-handle {
  position: absolute;
  top: -100%;
}

#PostCardDetailRow{
  display: flex;
  margin-top: 2px;
  margin-left: 145px;
}
.PostCardContainer {
  background-color: #F6F6F6;
  width: 1400px;
  height: auto;
  margin: 40px auto;
  border-radius: 20px;
  box-shadow: 5px 5px 12px 0px rgba(54,53,53,0.15);
  color:black;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

#postCardWrapper {
    padding-bottom: 50px;
    background-color: #FBF9FB;
}
.PostCardContainer {
  background-color: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.PostCardContainer:hover {
  box-shadow: none;
  background-color: transparent;
}

.shareAndSeeMore {
  display: -webkit-flex;
    display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
  -webkit-align-items: center;
  align-items: center;
  font-size: 30px;
}

.moreDetails {
  background-color: transparent;
  border: 0;
  font-size: 30px;
}

.moreDetails:hover,
.moreDetails:focus {
  color: #00B4DB;
  cursor: pointer
}



.moreDetails,
.shareAndSeeMore {
  flex:1;
  position: relative;
}

.moreDetails .moreDetailsBar {
  height: 2px;
  width: 100px;
  background-color: #7F5E7E;
  margin-right: 5px;;
}

.shareAndSeeMore .moreDetails,
.shareAndSeeMore .shareSection {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.moreDetailsBar:hover, 
.moreDetailsBar:focus {
  background-color: #00B4DB;
  color: white;
  cursor: pointer;
}

.Details {
  background-color: transparent !important;
  border: 0 !important;
}

.shareSection {
  flex:1;
  justify-content: flex-end
}

.numberOfLikes {
  margin-left: 5px;
}

.shareImg {
  margin-right: 5px;
  margin-left: 40px;
}


.chevronSymbol {
  display: inline-block;
  margin-left: 10px;
}


.moreDetailsSection {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  border-radius: 10px;
  -webkit-display:flex;
  display:flex;
}

.moreDetailLeft,
.moreDetailRight {
  -webkit-flex:1;
  flex:1;
  padding-left: 20px;
}



.moreDetailsSection.hidden {
  height:0;
  font-size: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  transition: 
    height .5s ease-in-out,
    opacity .25s,
    font-size .5s .25s,
    margin .5s .25s,
    padding .5s .25s;
}

.moreDetailsSection.animated  {
  font-size: 12px;
  height : auto;
  transition: 
    height .25s,
    font-size .25s,
    margin .25s,
    padding .25s,
    opacity .5s .25s;
}


#photosHead {
  display: -webkit-flex;
  display: flex;
  height: 19%;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
}

#photosBar {
    background-color: #855B80;
    height: 50%;
    width: 3.5%;
}

#photosContainer {
  height: 350px;
  background-color: #855B80;
  padding: 10px 0 0 0;
}

#photosContainer img {
  height: 340px;
  object-fit: contain;
}

#postCardDetailsWrapper {
  margin-left: 40px;
}

.moreDetailsDetails {
  padding: 10px 40px;
}

.moreDetailsTitle {
  font-size:20px;
  margin-bottom: 10px;
}

.moreDetailsText {
  font-size: 20px;
  font-weight: 400;
  color: #7F5E7E;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
  box-shadow: none !important;
}

#qrCodeWrapper {
  -webkit-display: flex;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin: 20px 40px;
}

  

@media screen and (max-width: 1420px) {
  .PostCardContainer {
      width: 1000px;
      height: auto;
  }
  #CardFramePostCard{
    flex-direction: column;
}
}

@media screen and (max-width: 1020px) {
  #PostCardDetailRow{
    margin-top: 15px;
    margin-left: 40px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    margin-top: 15px;
  }
    
  .PostCardContainer{
      width: 720px;
  }
}

@media screen and (max-width: 768px) {
  .shareAndSeeMore {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
  }
  .shareAndSeeMore .moreDetails,
  .shareAndSeeMore .shareSection {
    width: 100%;
    font-size: 20px;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .moreDetails {
    font-size: 20px;
  }

  .moreDetailsSection {
    flex-direction: column;
  }

  .moreDetailLeft,
  .moreDetailRight {
    padding-left: 0;
  }

  #qrCodeWrapper {
    -webkit-display: flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 20px 40px;
  }
}

@media screen and (max-width: 740px){

  .PostCardContainer{
      width: 500px;
  }
}

@media screen and (max-width: 550px){
    
  .PostCardContainer{
      width: 400px;
      margin: 20px 0px;
  }
}

@media screen and (max-width: 420px){
    
  .PostCardContainer{
      /* width: calc(100% -20px); */
      width:300px;
  }
}
  
