#CarouselContainer {
    height: 864px;
    width: 62%;
    background-color:#855B80;
    position: relative;
    max-width: 1227px;
}

.SwiperContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.swiper-container {
height: 100%;
}

.swiper-wrapper {
    display: flex;
    align-items: center;
}

.swiper-slide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 80%;
    height: 80%;
    object-fit: contain;
    cursor: pointer;
  }

.swiper-slide-active img{
    width: 90%;
    height: 90%;
    cursor: initial;
}

.swiper-pagination-bullet{
    height: 10px;
    width: 10px;
    background-color: #1B0E26;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 1.2%
}

@media only screen and (max-width: 1024px) {
    #CarouselContainer {
        height: 500px;
        width: 100%;
    }
    .SubheadBoxActive {
        width: 20%;
    }

    .SubheadBoxes {
        width: 20%;
    }

    #OurAppBody {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    #OurAppContainer {
        height: auto
    }

    #OurAppTop {
        height: 150px;
    }
    
    #OurAppSubheads {
        height: auto;
    }

    .SubheadBoxActive {
        padding-bottom: 20px;
        height: auto;
    }

    .SubheadBoxes {
        margin-bottom: 20px;
    }

    .BaseSubheadTxt {
        font-size: 17px;
    }

    #StayOrganizedImg {
        width: 40%;
        height: auto;
    }

    #FindTheHorseOfYourDreamsImg {
        width: 40%;
        height: auto;
    }
    
    #CompetitivePricingImg {
        width: 40%;
        height: auto;
    }
    
    #ExcitingNewFeaturesImg {
        width: 40%;
        height: auto;
    }

    /* .adjustedSizeMobile {
        padding-bottom: 15px;
    } */

    #StayOrganizedBody {
        width: 100%;
        position: unset;
    }

    #FindtheHorseofYourDreamsBody{
        width: 100%;
        position: unset;
    }

    #CompetitivePricingBody{
        width: 100%;
        position: unset;
    }

    #ExcitingNewFeaturesBody {
        width: 100%;
        position: unset;
    }
}

@media only screen and (max-width: 545px) {
    .BaseSubheadTxt {
        display: none;
    }

    .SubheadBoxActive {
        padding-bottom: 10px;
        padding-top: 10px;
        width: 23.5%;
    }

    .SubheadBoxes {
        margin-bottom: 10px;
        padding-top: 10px;
        width: 23.5%;
    }

    #OurAppBody {
        width: 94%;
    }
}