@import '../../index.css';

#NavFrame{
    width: 100vw;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 2;
    background-color: white;
    border-bottom: #E6EAEA 1px solid;
}

.OpenNav {
    display: none;
}

#NavContainer{
    display: flex;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    max-width: 1920px;
    top: 0;

}

#NavBrandContainer {
    display: flex;
    justify-content: flex-start;
    margin-left: 40px;
    align-items: center;
}

#NavLogo{
    height: 70px;
    object-fit: contain;
}

#NavTitle{
    font-family: 'francisco_serial-regular';
    font-size: 36px;
    color: #1B0E26;
    letter-spacing: .06em;
    margin-left: 20px;
}

#LinkContainer {
    display: flex;
    justify-content: flex-end;
}

#HomeLink{
    display: flex;
    align-items: center;
    margin-right: 30px;

}

#NewsLink{
    display: flex;
    align-items: center;
    margin-right: 45px;
}

.NavText {
    font-family: poppins, sanserif;
    font-weight: 600;
    color: #855B80;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
}

@media screen and (max-width: 420px) {
    #NavBrandContainer {
        margin-left: 20px;

    }
    
    #NavHamburger{
        display: flex;
        height: 50px;
        width: auto;
        margin-right: 30px;
        cursor: pointer;
    }   
    
    .hamburgerStyling {
        display: flex;
        position: fixed;
        top: 100px;
        background-color: #855B80;
        height: 0px;
        width: 100vw;
        overflow: hidden;
        z-index: 20;
        transition: 0.3s ease-out all;
    }

    .OpenNav {
        transition: 0.3s ease-out all;
        height: 200px !important;
    }
    
    #MobileNavLinkContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
    }

    .NavBorder{
        display: flex;
        height: 1px;
        width: 100vw;
        background-color: black;
    }

    .MobileLink{
        height: 99.5px;
        line-height: 99.5px;
        display: flex;
        justify-content: flex-end;
        font-family: poppins, sanserif;
        font-weight: 400;
        color: #F6F6F6;
        cursor: pointer;
        text-decoration: none;
        font-size: 30px;
        margin-right: 40px;
    }

}