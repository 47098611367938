@import url(https://use.typekit.net/xqj7jov.css);
* {
    margin: 0;
  }
  
  body {
    background-color: var(--bg-color);
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

@font-face {
  font-family: 'francisco_serial-regular';
  src: url(/static/media/francisco_serial-regular-webfont.966d2410.woff2) format('woff2'),
       url(/static/media/francisco_serial-regular-webfont.b4c74916.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Adamina-Regular';
  src: url(/static/media/Adamina-Regular.0b5a0e1e.ttf) format('ttf');
}

.btnText {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 1.7;
  color: #FFFFFF;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

#MissionCarouselContainer {
  width: 100%;
  /* max-width: 1920px; */
  display: flex;
  background-color: #F6F6F6;
  justify-content: center;
  align-items: center;
}

#MissionCarouselFrame{
  width: 100%;
  max-width: 1980px;
  display: flex;
}

@media only screen and (max-width: 1024px) {

  #MissionCarouselFrame {
  height: auto;
  flex-direction: column;
  }
  
}

.pageContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.textContainer{
  max-width: 1096px;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1096px) {
  .textContainer {
    margin: 30px;
  }
}

.homeContainer{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
}

.home {
  margin-bottom: 0px !important;
  margin-left: 5px;
  color: #855B80;
}

.textContainer h2 {
  font-size: 30px;
  margin-bottom: 20px
}

.textContainer h3{
  margin-bottom: 15px;
  font-size: 24px;
}

.textContainer h4{
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}

.textContainer p {
  margin-bottom: 10px;
}

.textContainer li{
  margin-bottom: 15px
}


#NavFrame{
    width: 100vw;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 2;
    background-color: white;
    border-bottom: #E6EAEA 1px solid;
}

.OpenNav {
    display: none;
}

#NavContainer{
    display: flex;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    max-width: 1920px;
    top: 0;

}

#NavBrandContainer {
    display: flex;
    justify-content: flex-start;
    margin-left: 40px;
    align-items: center;
}

#NavLogo{
    height: 70px;
    object-fit: contain;
}

#NavTitle{
    font-family: 'francisco_serial-regular';
    font-size: 36px;
    color: #1B0E26;
    letter-spacing: .06em;
    margin-left: 20px;
}

#LinkContainer {
    display: flex;
    justify-content: flex-end;
}

#HomeLink{
    display: flex;
    align-items: center;
    margin-right: 30px;

}

#NewsLink{
    display: flex;
    align-items: center;
    margin-right: 45px;
}

.NavText {
    font-family: poppins, sanserif;
    font-weight: 600;
    color: #855B80;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
}

@media screen and (max-width: 420px) {
    #NavBrandContainer {
        margin-left: 20px;

    }
    
    #NavHamburger{
        display: flex;
        height: 50px;
        width: auto;
        margin-right: 30px;
        cursor: pointer;
    }   
    
    .hamburgerStyling {
        display: flex;
        position: fixed;
        top: 100px;
        background-color: #855B80;
        height: 0px;
        width: 100vw;
        overflow: hidden;
        z-index: 20;
        transition: 0.3s ease-out all;
    }

    .OpenNav {
        transition: 0.3s ease-out all;
        height: 200px !important;
    }
    
    #MobileNavLinkContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
    }

    .NavBorder{
        display: flex;
        height: 1px;
        width: 100vw;
        background-color: black;
    }

    .MobileLink{
        height: 99.5px;
        line-height: 99.5px;
        display: flex;
        justify-content: flex-end;
        font-family: poppins, sanserif;
        font-weight: 400;
        color: #F6F6F6;
        cursor: pointer;
        text-decoration: none;
        font-size: 30px;
        margin-right: 40px;
    }

}
#EmailFrame{
    height: 100vh;
    width: 100vw;
    display: flex;
    position: fixed;
    background-color: rgba(0, 0, 0, .4);
    z-index: 2;
    align-items: center;
    justify-content: center;
    
}

#PopupContainer{
    height: 220px;
    width: 800px;
    background-color: #3C2A42;
    display: flex;
    flex-direction: column;
}

#TopPopup{
    display: flex;
    justify-content: space-between;
    margin: 20px 30px;
}

#PopupTitle{
    font-family: 'Adamina-Regular';
    font-size: 30px;
    color: #F6F6F6;
}

#PopupClose{
    cursor: pointer;
}

#PopupDescription{
    font-size: 20px;
    font-family: 'poppins';
    margin: 0px 30px;
    color: #F6F6F6;
}

#PopupBottom {
    display: flex;
    align-items: center;
}

#PopupInput{
    margin: 0px 30px;
    margin-top: 30px;
    width: 550px;
    font-size: 24px;
    font-family: 'poppins';
    color: #F6F6F6;
    font-weight: 400;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #F6F6F6;
}

#PopupSignup{
    border: 1px solid #F6F6F6;
    margin-top: 30px;
    height: 70%;
    width: 15%;
    max-height: 40px;
    color: #F6F6F6;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    font-family: 'poppins';
    font-size: 22px;
}

#PopupInput::-webkit-input-placeholder {
    color: #F6F6F6 !important;
    opacity: 1;
    }

#PopupInput:-ms-input-placeholder {
    color: #F6F6F6 !important;
    opacity: 1;
    }

#PopupInput::placeholder {
    color: #F6F6F6 !important;
    opacity: 1;
    }

#PopupInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #F6F6F6;
}
    
#PopupInput::-ms-input-placeholder { /* Microsoft Edge */
    color: #F6F6F6;
}

#HidePopup{
    display: none;
}

.PopupEmailError {
    color: red;
    margin: 0px 30px;
    height: 21px;
    font-size: 18px;
    text-align: center;
}

.PopupEmailComplete {
    color: #F6F6F6;
    margin: 0px 30px;
    height: 21px;
    font-size: 18px;
    text-align: center;
}

#PopupMobileClose{
    display: none;
}

@media only screen and (max-width: 850px) {
    #PopupContainer{
        height: 320px;
        width: 270px;
        background-color: #3C2A42;
        display: flex;
        flex-direction: column;
    }
    
    #PopupBottom {
        flex-direction: column;
        align-items: center;
    }

    #TopPopup{
        margin-top: 20px;
        align-items: center;
        justify-content: center;
    }

    #PopupTitle{
        font-size: 15px;
        text-align: center;
    }

    #PopupClose{
        display: none
    }

    #PopupDescription{
        font-size: 14px;
        text-align: center;
    }

    #PopupInput{
        margin: 30px 0px;
        width: 200px;
        font-size: 14px;
    }

    #PopupSignup{
        height: 30px;
        width: 140px;
        margin: 0px;
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 30px;
    }

    #PopupMobileClose{
        display: unset;
        border: 1px solid #F6F6F6;
        margin-top: 0px;
        height: 30px;
        width: 140px;
        color: #F6F6F6;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        font-family: 'poppins';
        font-size: 16px;
    }

    .PopupEmailError{
        margin-top: 10px;
        font-size: 14px;
    }

    .PopupEmailComplete{
        margin-top: 10px;
        font-size: 14px;
    }

}
#LandingContainer{
    width: 100%;
    height: 950.4px;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
}

#BorderContainer {
    width: 100%;
    height: 100%;
    max-width: 1980px;
    background: rgb(127,94,126);
    background: linear-gradient(145deg, rgba(27,14,38,1) 0%, rgba(127,94,126,1) 100%);
    display: flex;
}

#LandContentContainer {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    background-color: white;
    margin: auto;    
    max-width: 1980px;
}

#LandContentContainer > div {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    
}

#LeftContentContainer {
    width: 55%;
    height: 100%;
    width: 100%;
}

#TopLeftLandContainer{
    display: flex;
    align-items: center;
    width: 100%;
    height: 22%;
    z-index: 1000;
}

#LandLogoContainer {
    height: 90%;
    max-height: 170px;
    margin-left: 3%;
    margin-top: 2%;
}

#LandEQIcon {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
 }

.BrandName{
    font-family: 'francisco_serial-regular';
    font-size: 70px;
    color: #1B0E26;
    letter-spacing: .11em;
    margin-left: 2%;
}

#MidLeftLandContainer {
    margin-top: 4%;
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
}

#LandingLine{
    height: 60%;
    width: 1px;
    background-color: #1B0E26;
    margin-left: 3%;
}

#LandingDescription{
    font-family: poppins, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    line-height: 230%;
    color: #855B80;
    margin-left: 3%;
    padding-left: 2%;
    border-left: solid 1px #1B0E26;
}


#RightContentContainer{
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4%;
    position: absolute;
    right: 0;
    z-index: 0;
}

#LandingScreen {
    height: auto;
    /* max-height: 700px; */
    max-width: 500px;
    width: 80%;
    z-index: 1;
}

#LowerContentContainer{
    position: absolute;
    height: 15%;
    width: 100%;
    bottom: 0px;
    background-color: #F6F6F6;
    z-index: 0;
    display: flex;
}

#SubscribeBtn {
    height: 60%;
    width: 20%;
    min-height: 50px;
    max-width: 380px;
    background-color: #1B0E26;
    margin-left: 2%;
    margin-top: 1.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;
    font-size: 24px;
    text-decoration: none;
}

@media screen and (max-width: 1750px) {
    #LandingDescription {
      font-size: 30px;
    }

    #MidLeftLandContainer {
        margin-top: 6%;
    }

    #LandingLine{
        height: 50%;
    }

    #SubscribeBtn {
        font-size: 20px;
        height: 55%;
        width: 17%;
        min-width: 250px;
    }
}


@media screen and (max-width: 1320px) {
    #LandingDescription {
      font-size: 26px;
    }

    #MidLeftLandContainer {
        margin-top: 6%;
    }

    #LandingLine{
        height: 45%;
    }

    #SubscribeBtn {
        font-size: 18px;
        height: 50%;
        width: 16%;
        min-width: 250px;
        margin-top: 2%;
    }

}

@media screen and (max-width: 1210px) {

    #LandLogoContainer{
        margin: 20px;
        height: auto;
        width: 70px;
    }

    .BrandName {
        font-size: 50px;
        margin-left: 0px;
    }
    
    #SubscribeBtn {
        font-size: 18px;
        height: 50%;
        width: 16%;
        min-width: 250px;
        margin-top: 2%;
    }

    #HomeLinkContainer a.NavText {
        font-size: 16px;
        margin: 10px;
    }
}

@media screen and (max-width: 930px) {
    #LeftContentContainer {
        width: 50%;
    }

    #RightContentContainer {
        margin-right: 0px;
        width: 50%;
        position:static;
    }

    #LandLogoContainer{
        width: 50px;
    }

    .BrandName {
        font-size: 40px;
        margin-left: 10px;
    }

    #HomeLinkContainer a.NavText {
        font-size: 16px;
        margin: 10px;
    }
}

@media screen and (max-width: 768px) {

    #LandingContainer {
        height: auto;
    }

    #LandContentContainer {
        width: 100%;
        height: 100%;
        margin: 20px;
    }

    #LandContentContainer > div {
        flex-direction: column;
        align-items: center;
    }

    #LeftContentContainer {
        width: 100%;
    }

    #RightContentContainer {
        width: 100%;
        position:static;
    }

    #LandingDescription {
        font-size: 23px;
        margin-right: 20px;
    }

    #LandingScreen {
        max-width: 300px;
    }

    #LowerContentContainer {
        position: unset;
        height: 85px;
        display: flex;
        align-items: center;
    }

    #SubscribeBtn {
        margin: 0px;
        margin-left: 20px;
        width: auto;
    }

    #HomeLinkContainer a.NavText {
        font-size: 16px;
        margin: 10px;
    }

}


@media screen and (max-width: 680px) {

    #TopLeftLandContainer {
        flex-direction: column;
        z-index: 1000;
    }

    #HomeLinkContainer {
        font-size: 18px !important;
    }

    #LandLogoContainer {
        width: 80px;
    }
    
    .BrandName {
        margin: 0px;
    }

    #MidLeftLandContainer {
        margin:20px 0px;
    }

    #LandingDescription {
        font-size: 20px;
        border: none;
        text-align: center;
    }
    
    #RightContentContainer { 
        margin-bottom: 20px;
        position:static;
    }

    #LowerContentContainer {
        justify-content: center;
    }

    #SubscribeBtn {
        font-size: 16px;
        width: auto;
        height: unset;
        min-width: unset;
        min-height: unset;
        padding: 10px 20px;
        margin: 0px;
    }
}
@media screen and (max-width: 425px) {

    #TopLeftLandContainer {
        flex-direction: column;
        z-index: 1000;
    }

    #LandLogoContainer {
        width: 80px;
    }
    
    .BrandName {
        margin: 0px;
    }

    #MidLeftLandContainer {
        margin:20px 0px;
    }

    #LandingDescription {
        font-size: 20px;
        border: none;
        text-align: center;
    }
    
    #RightContentContainer { 
        margin-bottom: 20px;
        position:static;
    }

    #LowerContentContainer {
        justify-content: center;
    }

    #SubscribeBtn {
        font-size: 16px;
        width: auto;
        height: unset;
        min-width: unset;
        min-height: unset;
        padding: 10px 20px;
        margin: 0px;
    }
}

@media screen and (max-width: 320px) {
    .BrandName {
        font-size: 30px;
    }

    #LandingDescription {
        font-size: 18px;
    }
}
#RibbonFrame{
    width: 100%;
    height: 129.6px;
    display: flex; 
    justify-content: center;
    background-color: #F6F6F6;
}

#RibbonContainer {
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
    display: flex;
    justify-content: space-between;
    max-width: 1980px;
}

#RibbonText {
    font-family: 'Adamina-Regular';
    font-size: 36px;
    margin-left: 2.5%;
    align-self: center;
    color: #855B80;
}

#BtnContainer{
    height: 100%;
    width: 760px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
}

#BannerAndroidLogo {
    height: 66%;
}

#AndroidBtn {
    height: 60%;
    width: 50%;
    max-width: 380px;
    min-height: 50px;
    background-color: #855B80;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;
    font-size: 20px;
}

#BannerAppleLogo{
    height: 70%;
    padding-right: 2px;
}

#AppleBtn {
    height: 60%;
    width: 50%;
    max-width: 380px;
    min-height: 50px;
    background-color: #3C2A42;
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;
    font-size: 20px;
}

@media screen and (max-width: 1388px) {

    #RibbonText {
        font-size: 28px;
    }

    #BtnContainer {
        width: auto;
    }

    #BannerAndroidLogo {
        height: 40px;
    }

    #AndroidBtn { 
        width: auto;
        height: auto;
        min-height: unset;
        padding: 10px 20px;
        font-size: 18px;
    }

    #BannerAppleLogo{
        height: 40px;
        padding-right: 2px;
    }

    #AppleBtn {
        width: auto;
        height: auto;
        min-height: unset;
        padding: 10px 20px;
        font-size: 18px;
    }
}

@media screen and (max-width: 1024px) {

    #RibbonFrame {
        height: auto;
    }

    #RibbonContainer {
        flex-direction: column;
        height: 100%;
    }
    #RibbonText {
        margin: 20px 20px;
        text-align: center;
    }

    #BtnContainer {
        justify-content: center;
        padding-right: 0px;
        margin-bottom: 20px;
    }
}


@media screen and (max-width: 670px) {

    #BtnContainer {
        flex-direction: column;
        height: auto;
        align-items: center;
    }

    #BannerAndroidLogo {
        height: 30px;
    }

    #AndroidBtn {
        padding: 10px 20px;
        font-size: 16px;
        margin-bottom: 20px;
    }

    #BannerAppleLogo{
        height: 30px;
    }

    #AppleBtn {
        padding: 10px 20px;
        font-size: 16px;
        margin: 0px;
    }
}

@media screen and (max-width:316px) {

    #AndroidBtn {
        padding: 10px 10px;
        font-size: 14px;
    }

    #AppleBtn {
        padding: 10px 10px;
        font-size: 14px;
    }
}
#MissionContainer {
    height: 864px;
    width: 38%;
    background-color: #FBF9FB;
}

#MissionTop {
    display: flex;
    height: 19%;
    width: 100%;
    align-items: center;
}

#MissionHead {
    background-color: #855B80;
    height: 50%;
    width: 3.5%;
    margin-left: 7.5%;
}

#OurMissionText{
    font-family: 'Adamina-Regular';
    font-size: 34px;
    margin-left: 2%;
    color: #1B0E26;
}

.MissionContent{
    height: 27%;
    width: 100%;
    background-color: #FBF9FB;
    display: flex;
    flex-direction: column;
}

.MissionSubHead {
    display: flex;
}

.MissionSubHeadText{
    font-family: 'Adamina-Regular';
    font-size: 30px;
    margin-left: 18%;
    border-bottom: 1px solid black;
    padding-bottom: .5%;

}

#MissionSubHeadList{
    height: 26px;
    margin-left: 2% ;
    margin-top: 1.3%;
}

#MissionSubHeadGive{
    height: 30px;
    margin-left: 2% ;
    margin-top: 1.2%;
}

#MissionSubHeadHeart{
    height: 28px;
    margin-left:2% ;
    margin-top: 1.5%;
}

.MissionBodyText {
    margin-left: 18.3%;
    margin-top: 1.2%;
    font-size: 18px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 1.5rem;
}

@media only screen and (max-width: 1024px) {

    #MissionContainer {
        height: 530px;
        width: 100%;
        padding-bottom: 20px;
    }

    .MissionContent {
        height: auto;
        margin-bottom: 40px;
    }

    .MissionSubHeadText {
        margin-left: 7.5%;
        margin-right: 7.5%;
    }

    .MissionBodyText {
        margin-left: 7.5%;
        margin-right: 7.5%;
    }
    
}

@media only screen and (max-width: 420px) {
    #MissionContainer {
        height: auto;
    }

    #MissionTop {
        height: 100px;
    }
}
#CarouselContainer {
    height: 864px;
    width: 62%;
    background-color:#855B80;
    position: relative;
    max-width: 1227px;
}

.SwiperContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.swiper-container {
height: 100%;
}

.swiper-wrapper {
    display: flex;
    align-items: center;
}

.swiper-slide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 80%;
    height: 80%;
    object-fit: contain;
    cursor: pointer;
  }

.swiper-slide-active img{
    width: 90%;
    height: 90%;
    cursor: auto;
    cursor: initial;
}

.swiper-pagination-bullet{
    height: 10px;
    width: 10px;
    background-color: #1B0E26;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 1.2%
}

@media only screen and (max-width: 1024px) {
    #CarouselContainer {
        height: 500px;
        width: 100%;
    }
    .SubheadBoxActive {
        width: 20%;
    }

    .SubheadBoxes {
        width: 20%;
    }

    #OurAppBody {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    #OurAppContainer {
        height: auto
    }

    #OurAppTop {
        height: 150px;
    }
    
    #OurAppSubheads {
        height: auto;
    }

    .SubheadBoxActive {
        padding-bottom: 20px;
        height: auto;
    }

    .SubheadBoxes {
        margin-bottom: 20px;
    }

    .BaseSubheadTxt {
        font-size: 17px;
    }

    #StayOrganizedImg {
        width: 40%;
        height: auto;
    }

    #FindTheHorseOfYourDreamsImg {
        width: 40%;
        height: auto;
    }
    
    #CompetitivePricingImg {
        width: 40%;
        height: auto;
    }
    
    #ExcitingNewFeaturesImg {
        width: 40%;
        height: auto;
    }

    /* .adjustedSizeMobile {
        padding-bottom: 15px;
    } */

    #StayOrganizedBody {
        width: 100%;
        position: unset;
    }

    #FindtheHorseofYourDreamsBody{
        width: 100%;
        position: unset;
    }

    #CompetitivePricingBody{
        width: 100%;
        position: unset;
    }

    #ExcitingNewFeaturesBody {
        width: 100%;
        position: unset;
    }
}

@media only screen and (max-width: 545px) {
    .BaseSubheadTxt {
        display: none;
    }

    .SubheadBoxActive {
        padding-bottom: 10px;
        padding-top: 10px;
        width: 23.5%;
    }

    .SubheadBoxes {
        margin-bottom: 10px;
        padding-top: 10px;
        width: 23.5%;
    }

    #OurAppBody {
        width: 94%;
    }
}
#OurAppFrame{
    width: 100%;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
}

#OurAppContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    max-width: 1980px;
    margin-bottom: 40px;
}

#OurAppTop {
    display: flex;
    height: 150px;
    width: 100%;
    align-items: center;
}

#OurAppHead {
    background-color: #3C2A42;
    height: 39%;
    width: 1.33%;
    margin-left: 3%;
}

#OurAppText{
    font-family: 'Adamina-Regular';
    font-size: 46px;
    margin-left: .75%;
    color: #1B0E26;
}

#OurAppContentContainer {
    width: 80%;
    max-width: 800px;
}

#OurAppSubheads{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

.SubheadBoxes{
    /* height: 100%; */
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    
}

.SubheadBoxes:hover{
    background-color: #B1A5B0;
}

.SubheadBoxActive{
    /* height: 100%; */
    width: 25%;
    background-color: #3C2A42;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;

}

#StayOrganizedImg {
    width: 45%;
    margin-top: 5%;
}

.BaseSubheadTxt{
    font-family: 'Adamina-Regular';
    font-size: 28px;
    color: #1B0E26;
    margin-top: 2%;
    margin-right: 6%;
    margin-left: 6%;
    border-bottom: 1px solid transparent;
    padding-bottom: 2%;
}

.ActiveTxt {
    border-bottom: 1px solid #FBF9FB;
    color: #FBF9FB !important;
}

#FindTheHorseOfYourDreamsImg {
    width: 42%;
    margin-top: 5%;
}

#CompetitivePricingImg {
    width: 45%;
    margin-top: 5%;
}

#ExcitingNewFeaturesImg {
    width: 45%;
    margin-top: 5%;
}

#OurAppBody{
    width: 100%;
    /* height: 63%; */
    display: flex;
    position: relative;
    /* justify-content: center; */
    /* align-items: center; */
}

#BodyTxtContainer{
    /* height: 100%; */
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.OurAppBodyTxt{
    margin-left: 9%;
    font-size: 26px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 2.5rem;
    color: #FBF9FB;
}

.OurAppImg{
    margin: 30px 0px;
    height: auto;
    width: 85%;
}

#StayOrganizedBody{
    height: auto;
    width: 70%;
    background-color: #3C2A42;
    display: flex;
}

#FindtheHorseofYourDreamsBody{
    position: relative;
    height: auto;
    width: 70%;
    background-color: #3C2A42;
    /* margin-right: -7%; */
    left: 25%;
    display: flex;
}

#CompetitivePricingBody{
    position: relative;
    height: auto;
    width: 70%;
    background-color: #3C2A42;
    left: 5%;
    display: flex;
}

#ExcitingNewFeaturesBody{
    position: relative;
    height: auto;
    width: 70%;
    background-color: #3C2A42;
    left: 30%;
    display: flex;
}



#BodyImgContainer{
    /* height: 100%; */
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.OurAppMobleSubHeadDiv {
    width: 100%;
    padding-top: 20px;
    text-align: center;
    background-color: #3C2A42;
    color: white;
    font-weight: 700;
    font-size: 30px;
    display: none;
}

.OurAppMobleSubHeadDiv > p {
    margin: 0px 20px;
}

@media only screen and (max-width: 1024px) {
    .OurAppBodyTxt {
        font-size: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .OurAppMobleSubHeadDiv {
        display: block;
    }

    #OurAppSubheads > div > p {
        display: none;
    }

    #OurAppSubheads > div {
       padding-top: 10px;
    }

    #StayOrganizedBody{
        width: 100%;
    }
    
    #FindtheHorseofYourDreamsBody{
        position: unset;   
        width: 100%;
    }
    
    #CompetitivePricingBody{
        position: unset;
        width: 100%;
    }
    
    #ExcitingNewFeaturesBody{
        position: unset;
        width: 100%;
    }
}

@media only screen and (max-width: 547px) {

    #StayOrganizedBody{
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }

    #FindtheHorseofYourDreamsBody{
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }
    
    #CompetitivePricingBody{
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }
    
    #ExcitingNewFeaturesBody{
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }

    #BodyTxtContainer {
        width: 80%;
        margin-bottom: 20px;
    }

    .OurAppBodyTxt {
        margin: 0px;
    }

    #BodyImgContainer{
        width: auto;
    }

    .OurAppImg {
        width: 65%;
    }
}
#SubscribeFrame{
    width: 100%;
    height: 378px;
    background-color: #FBF9FB;
    display: flex;
    justify-content: center;
    align-items: center;
}

#SubscribeRibbonBody {
    width: 100%;
    height: 100%;
    max-width: 1980px;
    background-color:#FBF9FB ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#SubscribeRibbonHead{
   font-family: 'Adamina-Regular';
   font-size: 56px;
   color: #1B0E26;
   margin-bottom: 1%;
}

#SubsribeRibbonSubhead{
    font-family: 'poppins';
    font-size: 28px;
    font-weight: 400;
    line-height: 1.5rem;
    color: #3C2A42;
}

#SubsribeRibbonEmailContainer{
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    margin-top: 1.5%;
}

#SubsribeEmailTxt{
    /* width: 70%; */
    width: 500px;
    font-size: 24px;
    font-family: 'poppins';
    color: #1B0E26;
    font-weight: 400;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #1B0E26;
}

#SubscribeEmailBtn{
    border: 1px solid #1B0E26;
    height: 70%;
    width: 15%;
    max-height: 40px;
    margin-top: 2%;
    margin-left: 4%;
    color: #855B80;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    font-family: 'poppins';
    font-size: 22px;
    
}    

::-webkit-input-placeholder {
    color: #855B80;
    opacity: 1;
    }    

:-ms-input-placeholder {
    color: #855B80;
    opacity: 1;
    }    

::placeholder {
    color: #855B80;
    opacity: 1;
    }

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #855B80;
}
    
::-ms-input-placeholder { /* Microsoft Edge */
    color: #855B80;
}

#SubscribeEmailBtn:hover{
    background-color: #3C2A42;
    color: #FBF9FB;
}

textarea:focus, input:focus{
    outline: none;
}


.SubscribeEmailError {
    color: red;
    margin: 0px 30px;
    margin-top: 10px;
    height: 21px;
    font-size: 18px;
    text-align: center;
}

.SubscribeEmailComplete {
    margin: 0px 30px;
    margin-top: 10px;
    height: 21px;
    font-size: 18px;
    text-align: center;
}

@media only screen and (max-width: 768px) {

    #SubscribeFrame {
        height: auto;
        padding: 40px 0px;
    }

    #SubscribeRibbonHead {
        font-size: 40px;
        margin: 0px 20px;
        margin-bottom: 10px;
        text-align: center;
    }

    #SubsribeRibbonSubhead {
        font-size: 20px;
        margin: 0px 20px;
        text-align: center;
    }

    #SubsribeEmailTxt {
        width: 200px;
        font-size: 18px;
    }

    #SubscribeEmailBtn {
        width: 100px;
        font-size: 18px;
    }

}

@media only screen and (max-width: 425px) {

    #SubscribeRibbonHead {
        font-size: 30px;
    }

    #SubsribeRibbonSubhead {
        font-size: 18px;
    }

    #SubsribeRibbonEmailContainer {
        flex-direction: column;
        width: 80%;
        align-items: flex-end;
        margin-top: 20px;
    }

    #SubsribeEmailTxt {
        width: 100%;
        font-size: 18px;
    }

    #SubscribeEmailBtn {
        /* width: 100%; */
        margin: 0px;
        height: auto;
        line-height: unset;
        padding: 5px 0px;
        margin-top: 10px;
    }

}

#FooterFrame{
    height: 324px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

#FooterBody{
    height: 100%;
    width: 100%;
    /* width: 1980px; */
    background-color: white;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

#FooterCopyright {
    text-align: center;
    font-family: 'poppins';
    font-weight: 400;
    font-size: 28px;
    color: #1B0E26;
}

#FooterTitleContainer{
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:center ;
    margin-top: 1.5%;
}

#FootEQIcon {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

#FootEQTitle{
    font-family: 'francisco_serial-regular';
    font-size: 64px;
    color: #1B0E26;
    letter-spacing: .11em;
    margin-left: 1.5%;
}

#TermsContainer{
    display: flex;
    justify-content: center;
}

.TermLinks{
    margin: 10px 10px;
}

@media only screen and (max-width: 768px) {
    #FooterFrame {
        height: auto;
    }

    #FooterBody {
        margin: 40px 0px;
        height: auto;
    }

    #FooterTitleContainer {
        height: auto;
    }

    #FooterCopyright {
        margin: 0px 20px;
        margin-bottom: 10px;
        font-size: 20px;
    }

    #FootEQIcon {
        width: auto;
        height: 80px;
        object-fit: contain;
        max-width: unset;
        max-height: unset;
    }

    #FootEQTitle {
        font-size: 30px;
    }
}

@media only screen and (max-width: 425px) {
    #FooterTitleContainer {
        flex-direction: column-reverse;
    }

    #FootEQTitle {
        margin: 0px;
        margin-bottom: 20px;
    }
}
#NewsNavFrame{
    width: 100%;
    display: flex;
    justify-content: center;
}

#NewsNavContainer{
    margin-top:15px;
    display: flex;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    max-width: 1920px;

}

#NewsNavBrandContainer {
    display: flex;
    justify-content: flex-start;
    margin-left: 40px;
    align-items: center;
    text-decoration: none;
}

#NavLogo{
    height: 70px;
    object-fit: contain;
}

#NavTitle{
    font-family: 'francisco_serial-regular';
    font-size: 36px;
    color: #1B0E26;
    letter-spacing: .06em;
    margin-left: 20px;
}

#LinkContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#HomeLinkContainer {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
}

#HomeLink{
    display: flex;
    align-items: center;
    margin-right: 30px;

}

#NewsLink{
    display: flex;
    align-items: center;
    margin-right: 45px;
}

.NavText {
    font-family: poppins, sanserif;
    font-weight: 600;
    color: #855B80;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    .NavText {
        font-size: 16px;
    }
    #HomeLinkContainer {
        font-size: 18px !important;
    }
    #HomeLinkContainer a.NavText {
        font-size: 16px;
        margin: 10px;
    }
}

@media screen and (max-width: 550px) {

    #NewsNavBrandContainer {
        margin-left: 30px;
    }
    
    #NavLogo{
        height: 50px;
    }

    #NavTitle{
        font-size: 30px;
        letter-spacing: .04em;
        margin-left: 15px;
    }

    #HomeLink{
        margin-right: 20px;
    }

    #NewsLink{
        margin-right: 30px;
    }

    .NavText {
        font-size: 16px;
    }
    #HomeLinkContainer {
        font-size: 18px !important;
    }
    #HomeLinkContainer a.NavText {
        font-size: 16px;
    }

}

@media screen and (max-width: 420px) {
    #MobileHamburger{
        display: flex;
        height: 50px;
        width: auto;
        margin-right: 30px;
        cursor: pointer;
    }   
    
    .OpenNav {
        display: flex;
        position: fixed;
        top: 100px;
        background-color: #855B80;
        height: 200px;
        width: 100vw;
    }
    
    #MobileLinkContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
    }

    .NavBorder{
        display: flex;
        height: 1px;
        width: 100vw;
        background-color: black;
    }

    .MobileLink{
        height: 99.5px;
        line-height: 99.5px;
        display: flex;
        justify-content: flex-end;
        font-family: poppins, sanserif;
        font-weight: 400;
        color: #F6F6F6;
        cursor: pointer;
        text-decoration: none;
        font-size: 30px;
        margin-right: 40px;
    }

}
body {
    background-color: #EAE8EB;
}


#CardFrame{
    display: flex;
    width: 100%;
    /* justify-content: center; */
}

#CardHolder{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% - 320px);
    margin: 0 auto;
}

.CardContainer {
    background-color: #F6F6F6;
    width: calc( 50% - 80px);
    height: 550px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 5px 5px 12px 0px rgba(54,53,53,0.15);
    color:black;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.CardContainerFull {
    background-color: #F6F6F6;
    width: calc( 100% - 80px);
    height: 550px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 5px 5px 12px 0px rgba(54,53,53,0.15);
    color:black;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.CardContainer:hover {
    box-shadow: 5px 5px 12px 0px rgba(34, 44, 189, 0.15);
    background-color: #fff;
}

#NewsCardTop{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

#NewsCardTopLeft{
    margin-left: 40px;
    display: flex;
    align-items: center;
}

#ProfImgContainer {
    height: 80px;
    border-radius: 50%;
    display: flex;
}

#ProfileImg{
    object-fit: contain;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

#ProfileName{
    font-family: poppins, sans-serif;
    font-weight: 400;
    color: #1B0E26;
    font-size: 26px;
    margin-left: 20px;
    text-transform: capitalize;
}

#StatusBar{
    display: flex;
    justify-content: center;
    height: 45px;
    width: 120px;
    border-radius: 40px;
    margin-right: 20px;
    line-height: 45px;
    font-family: poppins, sans-serif;
    font-weight: 600;
    color: #F6F6F6;    
    box-shadow: 5px 3px 8px 0px rgba(54,53,53,0.15);
    align-self: center;
    font-size: 18px;
    text-transform: capitalize;
}

#DetailCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#DetailRow{
    display: flex;
    margin-top: 2px;
    margin-left: 145px;
}

#DetailRow2 {
    display: unset;
    margin-top: 2px;
}

.Details{
    font-family: poppins, sans-serif;
    font-weight: 400;
    color: #7F5E7E;
    font-size: 14px;
    margin-right: 15px;
    text-align: center;
    background-color: #fafafa;
    border: .3px solid #eee8e8;
    padding: 1.5px;
    border-radius: 5px;
}

#NewsTitle{
    display: flex;
    margin-left: 40px;
    margin-top: 20px;
    font-family: poppins, sans-serif;
    font-weight: 600;
    font-size: 24px;
}

#NewsDescript{
    display: flex;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 5px;
    font-family: poppins, sans-serif;
    font-weight: 400;
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

#NewsBottom{
    flex-grow: 1;
    height: 180px;
    display: flex;
    align-items: center;
    border-radius: 25px;
    margin-top: 20px;
    margin-bottom: 40px;
}

#BottomLeftLrg{
    display: flex;
    height: 180px;
    width: auto;
    /* max-width: 600px; */
    margin-left: 40px;
    border-radius: 25px;
    overflow: hidden;
}

#BottomLeftSm{
    display: flex;
    height: 180px;
    width: auto;
    max-width: 400px;
    margin-left: 40px;
    border-radius: 25px;
    overflow: hidden;
}

#BottomMiddleFull {
    display: flex;
    height: 180px;
    width: auto;
    max-width: 400px;
    margin-left: 30px;
    border-radius: 25px;
    overflow: hidden; 
}

#BottomMiddleHalves {
    display: flex;
    flex-direction: column;
    height: 180px;
    width: 270px;
    margin-left: 30px;
    max-width: 400px;
    overflow: hidden;
    justify-content: space-between;
}

.MiddleHalf {
    height: 80px;
    width: 180px;
    overflow: hidden;
    border-radius: 25px;
}

.MiddleHalf > img {
    min-height: 100%;
    max-width: 100%;
    object-position: top;
}

#BottomRight{
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;
    width: auto;
    margin-left: 30px;
}

#ExtraPicCol{
    display: flex;
    height: 110px;
    width: 125px;
    background-color: #EAE8EB;
    border-radius: 25px;
    margin-right: 20px;
}

#ExtraPicTxt{
    width: 100%;
    font-size: 22px;
    line-height: 110px;
    text-align: center;
    font-family: poppins, sans-serif;
    font-weight: 600;
    color: #B3A1B3;
}

#BottomRightNoExtra{
    display: flex;
    justify-content: flex-end;
    height: 100%;
    flex-grow: 1;
    width: auto;
    margin-left: 30px;
}

#LikeShareCont{
    margin-top: 150px;
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-right: 50px;
}

.Row {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
}

.NewsImg{
    display: flex;
    object-fit: contain;
}

.LikeShareTxt{
    font-family: poppins, sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    margin-left: 10px;
}

.Icons{
    height: 40px;
    width: auto;
}

a {
    text-decoration: none !important;
}

#CardHolder a {
    color: #1B0E26 !important;
}

@media screen and (max-width: 1820px) {
    .Details{
        font-size: 12px;
        padding: 1.5px;
    }
}
@media screen and (max-width: 1650px) {

    .CardContainer {
        height: auto;
    }
    #DetailRow,
    #DetailRow2{
        margin-top: 15px;
        margin-left: 40px;
        margin-right: 40px;
        flex-direction: column;
        align-items: flex-start;
    }

    
}
@media screen and (max-width: 1420px) {

    #CardHolder{
        width: calc(100%);
    }

    .CardContainer {
        width: 1000px;
        height: auto;
    }

    #CardFrame{
        flex-direction: column;
    }
    
    .MiddleHalf {
        width: 100%;
    }

    .MiddleHalf > img {
        min-height: 100%;
        height: 100%;
        min-width: 100%;
        max-width: unset;
    }

    #BottomMiddleHalves {
        width: 110px;
        margin-left: 20px;
    }
   
    #ExtraPicCol{
        width: 110px;
    }

    #LikeShareCont{
        width: 305px;
        margin-right: 30px;
    }
    
}

@media screen and (max-width: 1020px) {
    
    .CardContainer {
        width: 720px;
    }

    #NewsBottom{
        flex-direction: column;
        height: auto;
        justify-content: center;
    }

    #BottomLeftLrg{
        margin-left: 0;
        height: 280px;
    }


    #BottomLeftSm{
        max-width: unset;
        margin-left: 0px;
        justify-content: center;
        height: 280px;
    }

    #BottomMiddleHalves {
        flex-direction: row;
        height: auto;
        margin-left: 0px;
        margin-top: 10px;
        width: 450px;
        max-width: unset;
    }

    #BottomMiddleFull{
        height: auto;
        max-height: 250px;
        max-width: unset;
        margin-left: 0px;
        margin-top: 10px;
    }

    #BottomMiddleFull > Img{
        width: 450px;
    }


    .MiddleHalf {
        height: auto;
        width: 220px;
        border-radius: 25px;
    }

    .MiddleHalf > img {
        /* min-width: 100%; */
        /* max-width: unset; */
        height: auto;
        width: 100%;
    }

    #BottomRight{
        flex-direction: column;
        align-items: center;
        margin-left: 0px;
        margin-top: 10px;
    }

    #ExtraPicCol{
        width: 450px;
        height: 80px;
    }

    #ExtraPicTxt{
        line-height: 80px;
    }

    #LikeShareCont{
        margin:0;
        margin-top: 10px;
        flex-direction: row;
        width: auto;
    }

    .Row{
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media screen and (max-width: 740px){

    .CardContainer {
        width: 500px;
    }

    #ProfImgContainer {
        height: 70px;
        display: flex;
    }

    #ProfileName{
        font-size: 18px;
        margin-left: 15px;
    }

    #StatusBar{
        height: 35px;
        border-radius: 40px;
        line-height: 35px;
        font-size: 18px;
        margin-right: 5px;
    }

    #DetailRow,
    #DetailRow2{
        margin-top: 15px;
        margin-left: 40px;
        margin-right: 40px;
        justify-content: space-between;
        flex-direction: column;
    }
    
    .Details{
        margin-right: 0px;
        font-size: 12px;
        margin-right: 15px;
        text-align: left;
        background-color: transparent;
        border: 0;
        padding: 1.5px;
        border-radius: 5px;
    }

    #NewsTitle{
        margin-top: 10px;
        font-size: 22px;
    }

    #NewsDescript{
        margin-top: 5px;
        font-size: 16px;
    }

    #NewsBottom{
        margin-bottom: 20px;
    }

    #LikeShareCont{
        width: 400px;
    }

    #BottomRightNoExtra {
        margin-left: 0px;
    }

    .LikeShareTxt{
        font-size: 18px;
        line-height: 25px;
        margin-left: 10px;
    }

    .Icons{
        height: 25px;
        width: auto;
    }
    #BottomLeftSm{
        -webkit-transform: scale(.8);
                transform: scale(.8);
    }
    .NewsImg{
        border-radius: 25px;
        -webkit-transform: scale(.8);
                transform: scale(.8);
    }

}

@media screen and (max-width: 550px){
    
    .CardContainer {
        width: 400px;
        margin: 20px 0px;
    }
    
    #DetailRow{
        justify-content: space-evenly;
    }

    #DetailRow2 {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px 40px; 
    }

    #BottomLeftLrg{
        height: 200px;
    }

    #BottomLeftSm{
        height: 200px;
    }

    #BottomMiddleFull{
        max-height: 200px;
    }

    #BottomMiddleFull > Img{
        width: 300px;
    }

    #BottomMiddleHalves {
        width: 300px;
    }

    .MiddleHalf {
        height: auto;
        width: 220px;
        margin: 0px 5px;
    }

    #ExtraPicCol{
        width: 300px;
        height: 80px;
    }
    
}

@media screen and (max-width: 420px){
    
    .CardContainer {
        /* width: 300px; */
        width: calc(100% - 40px);
    }

    #NewsCardTop{
        flex-direction: column;
    }

    #NewsCardTopLeft{
        margin: 0;
        justify-content: center;
    }


    
    #ProfileName{
        font-size: 20px;
    }

    #StatusBar{
        margin-left: 140px;
        margin-top: -10px;
        margin-right: 5px;
    }
    
    #DetailRow{
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }

    /* #DetailRow2 {
        margin: 0px 10px;
    } */

    #NewsTitle{
        margin-left: 32px;
        font-size: 16px;
    }

    #NewsDescript{
        margin-left: 32px;
        margin-right: 32px;
        font-size: 16px;
    }

    #BottomLeftLrg{
        height: 150px;
    }

    #BottomLeftSm{
        height: 150px;
    }

    #BottomMiddleFull{
        height: 150px;
    }

    #BottomMiddleFull > Img {
        width: 225px;
    }

    #BottomMiddleHalves{
        width: 240px;
    }

    #ExtraPicCol{
        width: 225px;
        height: 60px;
    }

    #ExtraPicTxt{
        line-height: 60px;
        font-size: 20px;
    }

    #LikeShareCont {
        flex-direction: column;
        width: 300px;
    }

    .Row{
        justify-content: center;
        margin: 15px 0px;
    }

    .LikeShareTxt{
        font-size: 16px;
    }


}
.container {
  margin-top: 10px;
  width: 100%;
  -webkit-display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
}

.centered {
  /* position centered */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.filterWrapper {
  /* position: fixed; */
  /* top: 80px; */
  /* right: 30px; */
  /* z-index: 1000; */
  width: 300px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  border-radius:  30px;
  margin-right: 20px;
}

/* .rs-slider-progress-bar {
  background-color: #3C2A42;
}
.rs-slider-handle:before {
  border-color: #3C2A42;
} */
/* .filterWrapperVisible {
  position: fixed;
  top: 100px;
  right: 5px;
  z-index: 1000;
  width: 250px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
} */

#filterToggle {
  border-radius: 5px;
  background-color: #fff;
  border: 0;
  padding: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-self: flex-end;
}

#filterToggle:hover {
  cursor: pointer;
}

#filters {
  background-color: #f5f5f5;
  /* border: .5px solid #e5e5e5; */
  margin-top: 20px;
  padding: 20px;
  border-radius:  30px;
  width: 100%;
  margin-left: 20px;
  /* overflow-y: scroll; */
  /* height: 600px; */
}

.filterSection {
  margin-bottom: 10px;
  background-color: #fff;
  -webkit-display: flex;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px 20px;
}

.filterSectionLabel {
  font-weight: 700;
  margin-bottom: 10px;
}

.sliderMarkers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 10px;
  margin: 5px -5px 10px -5px;
}

.sliderMarkers>span>span {
  font-weight: 700;
}

.maxHeight {
  width: 85px;
}

#filterButtonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

#applyFilters {
  background-color: #3C2A42;
  border: .5px solid #e5e5e5;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  flex: 3 1;
  color: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: 900;
  border-radius: 0 10px 10px 0;
}

.resetFilters {
  border: .5px solid #e5e5e5;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  flex: 1 1;
  transition: all 0.3s ease-in-out;
  font-weight: 900;
  border-radius: 10px 0 0 10px;
}

.resetResultFilters {
  border-radius: 10px;
}

#applyFilters:hover,
.resetFilters:hover {
  background-color: #00B4DB;
}

.resetFilters:hover {
  color: #fff;
}

.noResults {
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
  background-color: #fafafa;
  padding: 16px;
  letter-spacing: 2px;
  max-width: 600px;
  margin: 10px auto;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.noResultsHeader {
  font-weight: 700;
  font-size: 18px;
  color: #444;
}

.noResultsText {
  font-size: 18px;
  color: #444;
  margin-top: 10px;
  text-align: center;
}

.filterResults {
  border-color: #ccc;
  border-width: 1px;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fafafa;
}

.filterText {
  letter-spacing: 2px;
  flex-wrap: wrap;
  font-size: 13px;
  text-align: center;
}

.filterLabel {
  font-weight: 700;
  font-size: 16px;
}

@media screen and (max-width: 1420px) {

  .filterWrapper,
  .filterWrapperVisible {
    width: 1000px;
    top: 85px;
    align-self: center;
    padding: 10px 30px;
  }

  #filters {
    width: auto;
  }

  .noResults {
    max-width: 600px;
  }
}

@media screen and (max-width: 1020px) {

  .filterWrapper {
    width: 720px;
  }
  #filters {
    width: auto;
  }
}

@media screen and (max-width: 740px) {

  .filterWrapper,
  .filterWrapperVisible {
    width: 500px;
    top: 85px;
  }
  #filters {
    width: auto;
  }

  .noResults {
    max-width: 600px;
  }
}

@media screen and (max-width: 550px) {

  .filterWrapper {
    width: 400px;
    margin: 20px 0px;
  }
  #filters {
    width: auto;
  }
}

@media screen and (max-width: 420px) {

  .filterWrapper {
    width: calc(100% -40px);
    padding: 10px 5px;
  }
  #filters {
    width: auto;
  }

  .noResults {
    max-width: 360px;
  }
}



@media screen and (max-width: 360px) {

  .filterWrapper {
    width: calc(100% -20px);
  }
  .noResults {
    max-width: 340px;
  }
}
/* You need to reset the box-sizing back to normal because the rsuite messes it for you...not cool */
*, :after, :before { 
  box-sizing: unset !important;
}

/* Since the rsuite package sliders handles depend on the 
box-sizing being set to border-box, need to update it 
*/
#CardFramePostCard{
  display: flex;
  justify-content: center;
}
.rs-slider-handle {
  position: absolute;
  top: -100%;
}

#PostCardDetailRow{
  display: flex;
  margin-top: 2px;
  margin-left: 145px;
}
.PostCardContainer {
  background-color: #F6F6F6;
  width: 1400px;
  height: auto;
  margin: 40px auto;
  border-radius: 20px;
  box-shadow: 5px 5px 12px 0px rgba(54,53,53,0.15);
  color:black;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

#postCardWrapper {
    padding-bottom: 50px;
    background-color: #FBF9FB;
}
.PostCardContainer {
  background-color: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.PostCardContainer:hover {
  box-shadow: none;
  background-color: transparent;
}

.shareAndSeeMore {
    display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
  align-items: center;
  font-size: 30px;
}

.moreDetails {
  background-color: transparent;
  border: 0;
  font-size: 30px;
}

.moreDetails:hover,
.moreDetails:focus {
  color: #00B4DB;
  cursor: pointer
}



.moreDetails,
.shareAndSeeMore {
  flex:1 1;
  position: relative;
}

.moreDetails .moreDetailsBar {
  height: 2px;
  width: 100px;
  background-color: #7F5E7E;
  margin-right: 5px;;
}

.shareAndSeeMore .moreDetails,
.shareAndSeeMore .shareSection {
  display: flex;
  align-items: center;
}
.moreDetailsBar:hover, 
.moreDetailsBar:focus {
  background-color: #00B4DB;
  color: white;
  cursor: pointer;
}

.Details {
  background-color: transparent !important;
  border: 0 !important;
}

.shareSection {
  flex:1 1;
  justify-content: flex-end
}

.numberOfLikes {
  margin-left: 5px;
}

.shareImg {
  margin-right: 5px;
  margin-left: 40px;
}


.chevronSymbol {
  display: inline-block;
  margin-left: 10px;
}


.moreDetailsSection {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  border-radius: 10px;
  -webkit-display:flex;
  display:flex;
}

.moreDetailLeft,
.moreDetailRight {
  flex:1 1;
  padding-left: 20px;
}



.moreDetailsSection.hidden {
  height:0;
  font-size: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  transition: 
    height .5s ease-in-out,
    opacity .25s,
    font-size .5s .25s,
    margin .5s .25s,
    padding .5s .25s;
}

.moreDetailsSection.animated  {
  font-size: 12px;
  height : auto;
  transition: 
    height .25s,
    font-size .25s,
    margin .25s,
    padding .25s,
    opacity .5s .25s;
}


#photosHead {
  display: flex;
  height: 19%;
  width: 100%;
  align-items: center;
}

#photosBar {
    background-color: #855B80;
    height: 50%;
    width: 3.5%;
}

#photosContainer {
  height: 350px;
  background-color: #855B80;
  padding: 10px 0 0 0;
}

#photosContainer img {
  height: 340px;
  object-fit: contain;
}

#postCardDetailsWrapper {
  margin-left: 40px;
}

.moreDetailsDetails {
  padding: 10px 40px;
}

.moreDetailsTitle {
  font-size:20px;
  margin-bottom: 10px;
}

.moreDetailsText {
  font-size: 20px;
  font-weight: 400;
  color: #7F5E7E;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
  box-shadow: none !important;
}

#qrCodeWrapper {
  -webkit-display: flex;
  display: flex;
  justify-content: flex-end;
  margin: 20px 40px;
}

  

@media screen and (max-width: 1420px) {
  .PostCardContainer {
      width: 1000px;
      height: auto;
  }
  #CardFramePostCard{
    flex-direction: column;
}
}

@media screen and (max-width: 1020px) {
  #PostCardDetailRow{
    margin-top: 15px;
    margin-left: 40px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    margin-top: 15px;
  }
    
  .PostCardContainer{
      width: 720px;
  }
}

@media screen and (max-width: 768px) {
  .shareAndSeeMore {
    flex-direction: column;
    align-items: center;
  }
  .shareAndSeeMore .moreDetails,
  .shareAndSeeMore .shareSection {
    width: 100%;
    font-size: 20px;
    justify-content: center;
  }

  .moreDetails {
    font-size: 20px;
  }

  .moreDetailsSection {
    flex-direction: column;
  }

  .moreDetailLeft,
  .moreDetailRight {
    padding-left: 0;
  }

  #qrCodeWrapper {
    -webkit-display: flex;
    display: flex;
    justify-content: center;
    margin: 20px 40px;
  }
}

@media screen and (max-width: 740px){

  .PostCardContainer{
      width: 500px;
  }
}

@media screen and (max-width: 550px){
    
  .PostCardContainer{
      width: 400px;
      margin: 20px 0px;
  }
}

@media screen and (max-width: 420px){
    
  .PostCardContainer{
      /* width: calc(100% -20px); */
      width:300px;
  }
}
  

.pageTitleContainer {
  -webkit-display: flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 10px;
  height: 100px;
  width: 1400px;
}

.pageTitleBar {
  height: 100px;
  width: 30px;
  background-color: #3C2A42;
  margin-right: 10px;
}
.pageTitleBar.scaleDown,
.pageTitleContainer.scaleDown   {
  height: 70px;
}

.pageTitle {
  font-size: 34px;
  font-weight: bold;
  color: #3C2A42;
  font-family: 'Adamina-Regular';
}

.pageTitle.scaleDown {
  font-size: 24px !important;
}

@media screen and (max-width: 550px){

  .pageTitleContainer {
    width: calc(100% - 20px)!important;
    padding: 10px;
  }

  .pageTitleBar {
    height: 70px;
    width: 20px;
    margin-right: 10px;
  }
}
#qrCodeContainer {
  position: relative;
  /* width: 200px;
  height: 45px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  /* padding: 7px 15px; */
  padding: 15px 50px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

#qrCodeContainer span {
  display: inline-block;
  margin-left: 20px;
  font-size: 25px;
  font-weight: 500;
  color:#855B80;
}

@media screen and (max-width: 768px) {
  #qrCodeContainer {
    position: relative;
    /* width: 150px;
    height: 45px; */
    padding: 15px 50px;
  }
  
  #qrCodeContainer span {
    display: inline-block;
    margin-left: 10px;
    font-size: 13px;
    font-weight: 600;
  }
}
#blogsWrapper {
  width:100%;
  background-color: #FBF9FB;
}

#contentWrapper,
#relatedArticlesWrapper {
  width: 1100px;
  -webkit-display: flex;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-top: 50px;
}

.cardWrapper {
  width: calc(33% - 20px);
  margin: 10px;
  background-color: #FBF9FB;
  border-radius: 10px;
  text-decoration: none;
  color: #000 !important;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  padding-bottom: 50px;
}

.cardWrapper:hover,
.cardWrapper:focus  {
  box-shadow: 0px 0px 50px 0px rgba(10, 5, 31, 0.2);
}

.cardImage {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.blogPostTitle {
  font-size: 20px;
  font-weight: 600;
  color: #3C2A42;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
}

.blogDateAndSubject,
.blogPostDateAndSubject {
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  font-weight: 500;
  color: #855B80;
}

.blogPostDateAndSubject {
  padding: 20px;
  padding-left: 0;
}

.blogBody,
.postBlogBody {
  padding: 20px;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.postBlogBody {
  padding: 0;
}

.readMore {
  font-size: 18px;
  font-weight: 800;
  color: #855B80;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  text-align: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

/* Single Post Card Page */
#blogPostWrapper {
  width: 1100px;
  margin: 0 auto;
}

#blogPostContent {
  padding: 20px;
}


#backToBlock  {
  text-decoration: none;
  font-size: 16px;
  color: #3C2A42;
  font-weight: 600;
}

.blogPostParagraph {
  text-indent: 50px;
  padding-bottom: 20px;
  font-size: 18px;
}

#backToBlock:hover,
#backToBlock:focus {
  text-decoration: underline;
}
#backToBlock i{
  margin-right: 5px;
}

#postTitle {
  font-size: 34px;
  font-weight: bold;
  color: #3C2A42;
  font-family: 'Adamina-Regular';
  margin-top: 20px;
}

#relatedArticlesWrapper {
  padding-top: 20px !important;
}

.blogPostImage {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1100px){
  .cardWrapper {
    width: calc(100% - 20px);
  }
  #contentWrapper,
  #blogPostWrapper,
  #relatedArticlesWrapper {
    width: 100% !important;
  }

  .cardWrapper {
    width: calc(50% - 20px);
    margin: 10px;
    background-color: #FBF9FB;
    border-radius: 10px;
    text-decoration: none;
    color: #000 !important;
    transition: all 0.3s ease-in-out;
    margin-bottom: 30px;
  }

  .blogPostImage {
    height: 200px;
  }

}

@media screen and (max-width: 550px){
  .cardWrapper {
    width: calc(100% - 20px);
  }

  #contentWrapper,
  #blogPostWrapper,
  #relatedArticlesWrapper {
    width: 100% !important;
    flex-direction: column;
    padding-top: 10px;
  }

  .blogPostImage {
    height: 190px;
  }
}

@media screen and (max-width: 400px){
  .cardWrapper {
    width: calc(100% - 20px);
  }

  #contentWrapper,
  #blogPostWrapper,
  #relatedArticlesWrapper {
    width: 100% !important;
    flex-direction: column;
    padding-top: 10px;
  }

  .blogPostImage {
    height: 150px;
  }
}
#container {
  width: 100%;
  height:300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  font-weight: bold;
  flex-direction: column;
}

.actionButton {
  background-color: #444;
  color: #fff;
  border-radius: 75px;
  margin: 0 10px;
  padding: 10px;
  width: 100px;
  margin-top: 20px;
  border: 0;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}
#LandLogoContainer {
  /* height: 90%; */
  max-height: 170px;
  margin-left: 3%;
  margin-top: 2%;
}

.logo {
  height: 170px;
}
#webFormWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(127,94,126,1);
}

#formHeader {
    display: flex;
    height: 19%;
    width: 100%;
    align-items: center;
}

#sectionTitle{
  font-family: 'Adamina-Regular';
  font-size: 34px;
  color: #000;
  margin: 20px 0;
}
#sectionTitle::before {
  content: "";
  display: block;
  width: 20px;
  height: 34px;
  background-color: #000;
  left: 10px;
  position: absolute;
}

.container {
  margin: 20px;
  background-color: #fff;
  padding: 20px;
  font-family: 'Adamina-Regular';
  font-size: 20px;
}

.inner {
  border-radius: 3px;
  width: 800px;
  margin: 20px auto;
  padding: 40px;
  border: 1px solid #f5f5f5;
  position: relative;
}

.form-group {
  margin-bottom: 15px;
  width: 320px;
}

.form-group label {
  color: #444;
  font-size: 20px;
  height: 20px;
  position: absolute;
}

.form-group label.checkBox {
  color: #444;
  font-size: 20px;
  height: 20px;
  position: static;
}

.cityState {
  display: flex;
  width: 300px;
}

.cityState .form-control {
  width: 70px;

}
.cityState #city {
  width: 200px;
  margin-right: 10px;
}

.form-control {
  height: auto;
  padding: 10px;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #ccc;
  color:#000;
  font-family: 'Adamina-Regular';
  font-size: 19px;
}

.form-control:focus {
  border: 2px solid #3384FF;
  box-shadow: none;
}

textarea {
  font-family: 'Adamina-Regular';
  font-size: 19px;
}

.form-control[type="radio"] {
  width: 40px;
  height: 20px;
  margin-bottom: 20px;
}

.userTypeInfo {
  margin-top: 30px;
  color:grey;
}

.existingUserInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  font-weight: bold;
}

.formButton {
  /* width: 300px; */
  padding: 15px 50px;
  /* height: 50px; */
  margin: 0 10px 0 0;
  background-color: #1B0E26;
  color: #fff;
  font-family: 'Adamina-Regular';
  font-size: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.addMorePostsInfo {
  width: 100%;
  padding: 20px;
  background-color: #fafafa;
  border-color: #ccc;
  border-radius: 3px;
  margin-right: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: center;
}
.addMore {
  background-color: #4F63FF;
  color: #fff;
}
.numberOfPostsInfo {
  width: 100%;
  background-color: #4F63FF;
  font-size: 18px;
  color: #fff;
  
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
  /* display: flex; */
}

.postHeaderWrapper {
  display: flex;
  padding: 10px 0;
  width: 100%;
}

.toggleViewAllPosts {
  background-color: #fff;
  border: none;
  margin: 10px;
  padding: 10px 30px;
  font-weight: bold;
  border: 1px solid transparent;
}

.toggleViewAllPosts:hover {
  background-color: #4F63FF;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
}

.listOfPostsWrapper {
  background-color: #fafafa;
  border: 1px solid #4F63FF;
  display: flex;
  justify-content: center;
  flex-direction: column;
  
}

.postDetailsWrapper {
  display: flex;
  /* justify-content: center; */
  color: grey;
  font-size: 13px;
  font-weight: 200;
  font-family: arial;
  text-align: left;
  /* align-items: center;
  align-self: center; */
  padding: 10px 100px;
  border: 1px dotted #ccc;
  margin: 10px 20px;
  border-radius: 5px;
  width: 500px;
}

.postDetailsWrapper span {
  font-weight: 400;
  color: #000;
}

.postDetailsWrapper span.type {
  color: green;
  font-weight: bold;
  text-transform: capitalize;
}



.postDetailsWrapper h1 {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #4F63FF;
  color: #fff;
  border-radius: 75px;
  margin: 0 10px;
  padding: 20px;
}



.errorMessage {
  color: red;
  font-size: 16px;
  margin-top: 5px;
}

.divider {
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  margin: 20px 0;
}

.checkboxRow {
  display: flex;
  width: 300px;
  justify-content: flex-start;
  align-items: center;
}

.checkboxRow label {
  margin-left: 10px;
}

.isResetting {
  width: 100%;
  height:300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  font-weight: bold;
}

.stripeCardElementWrapper {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #fafafa;
}

.showHideImageBtn {
  background-color: #4F63FF;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
}
.imageStyle {
  box-shadow: -11px 28px 58px -8px rgba(97,90,97,0.75);
}

.userDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 90%;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.userDetails p:nth-child(1) {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  color: grey;
}

.userDetails span {
  font-size: 16px;
  font-weight: bold;
}

.nextInfo {
  margin-top: 20px;
  font-weight: 100;
  color: #353958;
}

.userTypeHeader {
  margin-bottom: 10px;
  color:grey;
}
