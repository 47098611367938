@import url('https://use.typekit.net/xqj7jov.css');

* {
    margin: 0;
  }
  
  body {
    background-color: var(--bg-color);
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

@font-face {
  font-family: 'francisco_serial-regular';
  src: url('./fonts/FranciscoSerial/francisco_serial-regular-webfont.woff2') format('woff2'),
       url('./fonts/FranciscoSerial/francisco_serial-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Adamina-Regular';
  src: url('./fonts/Adamina/Adamina-Regular.ttf') format('ttf');
}

.btnText {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 1.7;
  color: #FFFFFF;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

#MissionCarouselContainer {
  width: 100%;
  /* max-width: 1920px; */
  display: flex;
  background-color: #F6F6F6;
  justify-content: center;
  align-items: center;
}

#MissionCarouselFrame{
  width: 100%;
  max-width: 1980px;
  display: flex;
}

@media only screen and (max-width: 1024px) {

  #MissionCarouselFrame {
  height: auto;
  flex-direction: column;
  }
  
}

.pageContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.textContainer{
  max-width: 1096px;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1096px) {
  .textContainer {
    margin: 30px;
  }
}

.homeContainer{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
}

.home {
  margin-bottom: 0px !important;
  margin-left: 5px;
  color: #855B80;
}

.textContainer h2 {
  font-size: 30px;
  margin-bottom: 20px
}

.textContainer h3{
  margin-bottom: 15px;
  font-size: 24px;
}

.textContainer h4{
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}

.textContainer p {
  margin-bottom: 10px;
}

.textContainer li{
  margin-bottom: 15px
}

