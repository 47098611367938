#blogsWrapper {
  width:100%;
  background-color: #FBF9FB;
}

#contentWrapper,
#relatedArticlesWrapper {
  width: 1100px;
  -webkit-display: flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-top: 50px;
}

.cardWrapper {
  width: calc(33% - 20px);
  margin: 10px;
  background-color: #FBF9FB;
  border-radius: 10px;
  text-decoration: none;
  color: #000 !important;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  padding-bottom: 50px;
}

.cardWrapper:hover,
.cardWrapper:focus  {
  box-shadow: 0px 0px 50px 0px rgba(10, 5, 31, 0.2);
}

.cardImage {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.blogPostTitle {
  font-size: 20px;
  font-weight: 600;
  color: #3C2A42;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
}

.blogDateAndSubject,
.blogPostDateAndSubject {
  -webkit-display: flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 5px 20px;
  font-weight: 500;
  color: #855B80;
}

.blogPostDateAndSubject {
  padding: 20px;
  padding-left: 0;
}

.blogBody,
.postBlogBody {
  padding: 20px;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.postBlogBody {
  padding: 0;
}

.readMore {
  font-size: 18px;
  font-weight: 800;
  color: #855B80;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  text-align: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

/* Single Post Card Page */
#blogPostWrapper {
  width: 1100px;
  margin: 0 auto;
}

#blogPostContent {
  padding: 20px;
}


#backToBlock  {
  text-decoration: none;
  font-size: 16px;
  color: #3C2A42;
  font-weight: 600;
}

.blogPostParagraph {
  text-indent: 50px;
  padding-bottom: 20px;
  font-size: 18px;
}

#backToBlock:hover,
#backToBlock:focus {
  text-decoration: underline;
}
#backToBlock i{
  margin-right: 5px;
}

#postTitle {
  font-size: 34px;
  font-weight: bold;
  color: #3C2A42;
  font-family: 'Adamina-Regular';
  margin-top: 20px;
}

#relatedArticlesWrapper {
  padding-top: 20px !important;
}

.blogPostImage {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1100px){
  .cardWrapper {
    width: calc(100% - 20px);
  }
  #contentWrapper,
  #blogPostWrapper,
  #relatedArticlesWrapper {
    width: 100% !important;
  }

  .cardWrapper {
    width: calc(50% - 20px);
    margin: 10px;
    background-color: #FBF9FB;
    border-radius: 10px;
    text-decoration: none;
    color: #000 !important;
    transition: all 0.3s ease-in-out;
    margin-bottom: 30px;
  }

  .blogPostImage {
    height: 200px;
  }

}

@media screen and (max-width: 550px){
  .cardWrapper {
    width: calc(100% - 20px);
  }

  #contentWrapper,
  #blogPostWrapper,
  #relatedArticlesWrapper {
    width: 100% !important;
    flex-direction: column;
    padding-top: 10px;
  }

  .blogPostImage {
    height: 190px;
  }
}

@media screen and (max-width: 400px){
  .cardWrapper {
    width: calc(100% - 20px);
  }

  #contentWrapper,
  #blogPostWrapper,
  #relatedArticlesWrapper {
    width: 100% !important;
    flex-direction: column;
    padding-top: 10px;
  }

  .blogPostImage {
    height: 150px;
  }
}