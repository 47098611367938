@import '../../../index.css';

#EmailFrame{
    height: 100vh;
    width: 100vw;
    display: flex;
    position: fixed;
    background-color: rgba(0, 0, 0, .4);
    z-index: 2;
    align-items: center;
    justify-content: center;
    
}

#PopupContainer{
    height: 220px;
    width: 800px;
    background-color: #3C2A42;
    display: flex;
    flex-direction: column;
}

#TopPopup{
    display: flex;
    justify-content: space-between;
    margin: 20px 30px;
}

#PopupTitle{
    font-family: 'Adamina-Regular';
    font-size: 30px;
    color: #F6F6F6;
}

#PopupClose{
    cursor: pointer;
}

#PopupDescription{
    font-size: 20px;
    font-family: 'poppins';
    margin: 0px 30px;
    color: #F6F6F6;
}

#PopupBottom {
    display: flex;
    align-items: center;
}

#PopupInput{
    margin: 0px 30px;
    margin-top: 30px;
    width: 550px;
    font-size: 24px;
    font-family: 'poppins';
    color: #F6F6F6;
    font-weight: 400;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #F6F6F6;
}

#PopupSignup{
    border: 1px solid #F6F6F6;
    margin-top: 30px;
    height: 70%;
    width: 15%;
    max-height: 40px;
    color: #F6F6F6;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    font-family: 'poppins';
    font-size: 22px;
}

#PopupInput::placeholder {
    color: #F6F6F6 !important;
    opacity: 1;
    }

#PopupInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #F6F6F6;
}
    
#PopupInput::-ms-input-placeholder { /* Microsoft Edge */
    color: #F6F6F6;
}

#HidePopup{
    display: none;
}

.PopupEmailError {
    color: red;
    margin: 0px 30px;
    height: 21px;
    font-size: 18px;
    text-align: center;
}

.PopupEmailComplete {
    color: #F6F6F6;
    margin: 0px 30px;
    height: 21px;
    font-size: 18px;
    text-align: center;
}

#PopupMobileClose{
    display: none;
}

@media only screen and (max-width: 850px) {
    #PopupContainer{
        height: 320px;
        width: 270px;
        background-color: #3C2A42;
        display: flex;
        flex-direction: column;
    }
    
    #PopupBottom {
        flex-direction: column;
        align-items: center;
    }

    #TopPopup{
        margin-top: 20px;
        align-items: center;
        justify-content: center;
    }

    #PopupTitle{
        font-size: 15px;
        text-align: center;
    }

    #PopupClose{
        display: none
    }

    #PopupDescription{
        font-size: 14px;
        text-align: center;
    }

    #PopupInput{
        margin: 30px 0px;
        width: 200px;
        font-size: 14px;
    }

    #PopupSignup{
        height: 30px;
        width: 140px;
        margin: 0px;
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 30px;
    }

    #PopupMobileClose{
        display: unset;
        border: 1px solid #F6F6F6;
        margin-top: 0px;
        height: 30px;
        width: 140px;
        color: #F6F6F6;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        font-family: 'poppins';
        font-size: 16px;
    }

    .PopupEmailError{
        margin-top: 10px;
        font-size: 14px;
    }

    .PopupEmailComplete{
        margin-top: 10px;
        font-size: 14px;
    }

}